import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../Auth";

import NoteIcon from "../assets/images/note.svg";

import { Container } from "../components/Container.Style";
import Header from "../components/Header";
import ProfileComponent from "../components/Profile/Profile";
import PopupModal from "../components/PopupModal/PopupModal";
import AcqInfo from "../components/Acquaintances/AcquaintanceInfo";
import Button from "../components/Input/Button";
import Note from "../components/Input/Note";

import acquaintanceServices from "../services/acquaintance.services";

function Acquaintance() {
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const [editor, setEditor] = useState(false);
  const [acquaintance, setAcquaintance] = useState({});

  function updateAcquaintance() {
    if (progress) return;
    setProgress(false);
    acquaintanceServices
      .updateAcquaintance(acquaintance._id, { note: acquaintance.note })
      .then(() => {
        setProgress(false);
        setEditor(false);
        auth.setMessagePopup({
          message: `${acquaintance.attendeeId?.name || ""} Note Saved!`,
          status: "success",
        });
      })
      .catch((err) => {
        auth.setMessagePopup({ message: err.message, status: "failed" });
      });
  }

  useEffect(() => {
    acquaintanceServices
      .getAcquaintance(params.id)
      .then((res) => setAcquaintance(res.data.acquaintance || {}))
      .catch((err) => alert(err.message));
  }, [params.id]);

  return (
    <Container display="block">
      <Header
        child1={{
          classname: "left",
        }}
        child2={{
          classname: "center",
          head: "About",
        }}
        child3={{
          classname: "right",
        }}
        profile={auth?.user?.profile}
      />
      <ProfileComponent
        lastTag={{
          name: "Note",
          click: () => setEditor(true),
        }}
        profile={acquaintance.attendeeId || {}}
      />
      <PopupModal
        closeModal={() => setEditor(false)}
        headIcon={NoteIcon}
        headLabel={"Write a note"}
        active={editor}
      >
        <AcqInfo
          name={acquaintance.attendeeId?.name}
          company={acquaintance.attendeeId?.company}
          click={() => navigate(`/acquaintances/${acquaintance._id}`)}
          hideNote={true}
        />
        <Note
          placeholder={"Start writing here"}
          limit={200}
          value={acquaintance.note || ""}
          onChange={(value) => {
            setAcquaintance({ ...acquaintance, note: value });
          }}
        />
        <Button
          onClick={updateAcquaintance}
          name={progress ? "Saving..." : "Save"}
          style={{
            padding: "12px 17px",
            margin: "auto",
          }}
          btnType="loginBtn"
        />
      </PopupModal>
    </Container>
  );
}

export default Acquaintance;
