import { useState, useContext, createContext, useEffect } from "react";

import authServices from "./services/auth.services";

import Loader from "./components/Loading/Loader";
import MessageModal from "./components/PopupModal/MessageModal";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  
  const [messagePopup, setMessagePopup] = useState({
    status: "",
    message: "",
  });

  useEffect(() => {
    if (messagePopup.status !== "" && messagePopup.message !== "") {
      const timer = setTimeout(() => {
        setMessagePopup({ status: "", message: "" });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [messagePopup]);

  const [data, setData] = useState({ loading: true, logged: false });
  const openRoutes = ["/", "/forget-password", "/reset-password", "/register", "/policies/privacy"];

  useEffect(() => {
    authServices
      .validate()
      .then((res) => {
        setData({ loading: false, logged: true, user: res.data.user });
      })
      .catch((err) => {
        console.log(err);
        setData({ loading: false, logged: false });
        const isOpenRoute = openRoutes.find(
          (route) =>
            window.location.pathname === route ||
            window.location.pathname.startsWith("/register")
        );
        if (!isOpenRoute && window.location.pathname !== "/") {
          window.location.href = `/?redirect=${window.location.pathname}`;
        }
      });
  }, []);

  useEffect(() => {
    if (!data.logged) return;
    //if user already logged redirect him/her to dashboad from login
    if (window.location.pathname === "/") window.location.href = "/dashboard";
  }, [data.loading]);

  return (
    <>
      <AuthContext.Provider value={{ ...data, setData, setMessagePopup }}>
        {children}
      </AuthContext.Provider>
      <MessageModal
        message={messagePopup.message}
        status={messagePopup.status}
        close={() => setMessagePopup({ status: "", message: "" })}
      />
      <Loader loading={data.loading} />
    </>
  );
};

export default AuthProvider;
