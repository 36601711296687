import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  TableScroller,
  Table,
  TableHeader,
  TableBody,
  Tr,
  Td,
} from "../Table.Style";
import SortIcon from "../../assets/images/sort.svg";

function Sort(props) {
  return (
    <p
      id={props.id}
      className={`th ${props.id.startsWith("-") ? "up" : ""}`}
      onClick={props.sort}
    >
      <span>{props.label}</span>
      <img src={SortIcon} alt="sort" />
    </p>
  );
}

function AttendeesList(props) {
  const navigate = useNavigate();
  const [heads, setHeads] = useState([
    { label: "Name", id: "name" },
    { label: "Team", id: "team" },
    { label: "Company", id: "company" },
    { label: "State", id: "state" },
  ]);

  const [attendeesData, setAttendeesData] = useState([...props.attendees]);

  const extractNumber = (string) => {
    let matches = string.match(/\d+/);
    let numericValue = matches ? matches[0] || "0" : "0";
    return numericValue;
  };

  function sort(id, index) {
    props.sort(id);

    // Update the sorting indicator for the clicked column
    const asc = id.startsWith("-");
    if (asc) {
      heads[index].id = id.replace("-", "");
    } else {
      heads[index].id = `-${id}`;
    }
    setHeads([...heads]);

    // Sort the attendees based on the team number
    const sortedAttendees = [...props.attendees];
    sortedAttendees.sort((a, b) => {
      // const teamA = a.team || ""; // Handle the case where team is undefined
      // const teamB = b.team || "";

      let teamA = "";
      let teamB = "";

      if (heads[index].id == "team" || heads[index].id == "-team") {
        teamA = extractNumber(a[heads[index].id] || "0");
        teamB = extractNumber(b[heads[index].id] || "0");

        if (asc) {
          if (parseInt(teamA, 10) > parseInt(teamB, 10)) {
            return 0;
          } else {
            return 1;
          }
        } else {
          if (parseInt(teamB, 10) > parseInt(teamA, 10)) {
            return 0;
          } else {
            return 1;
          }
        }
      } else {
        teamA = a[heads[index].id] || "";
        teamB = b[heads[index].id] || "";

        if (asc) {
          return teamA.localeCompare(teamB, undefined, { numeric: true });
        } else {
          return teamB.localeCompare(teamA, undefined, { numeric: true });
        }
      }
    });
  }

  useEffect(() => {
    // console.log("props.attendees", props.attendees);

    let data = [...props.attendees];

    props.attendees.map((at, i) => {
      let st = at.otherInformation.find((a) => a.key == "state");
      if (st) {
        data[i].state = st.value || "Unassigned";
      } else {
        data[i].state = "Unassigned";
      }
    });
    setAttendeesData(data);
  }, [props.attendees]);

  useEffect(() => {
    // console.log("props.attendees", props.attendees);

    let data = [...props.attendees];

    props.attendees.map((ac, j) => {
      let su = ac.company;
      if (su) {
        data[j].company = su || "Unassigned";
      } else {
        data[j].company = "Unassigned";
      }
    });
    setAttendeesData(data);
  }, [props.attendees]);

  return (
    <TableScroller>
      <Table>
        <TableHeader>
          <Tr
            borderWidth={"0px"}
            borderRadius={"5px"}
            background={"transparent"}
          >
            {heads.map((head, index) => {
              return (
                <Td key={index}>
                  <Sort
                    label={head.label}
                    id={head.id}
                    sort={() => sort(head.id, index)}
                  />
                </Td>
              );
            })}
          </Tr>
        </TableHeader>
        <TableBody>
          {attendeesData
            // .filter((attendee) => {
            //   if (!props.filter) return true;
            //   if (!attendee?.name) return false;
            //   return attendee.name.match(new RegExp(props.filter, "i"));
            // })
            .filter((attendee) => {
              if (!props.filter) return true;
              if (!attendee) return false;
              const filterRegex = new RegExp(props.filter, "i");
              for (const key in attendee) {
                if (Object.prototype.hasOwnProperty.call(attendee, key)) {
                  const fieldValue = attendee[key];
                  if (fieldValue && fieldValue.toString().match(filterRegex)) {
                    return true;
                  }
                }
              }
              return false;
            })
            .map((attendee, index) => {
              return (
                <>
                  {attendee != null ? (
                    <Tr
                      key={index}
                      onClick={() => navigate(`/attendees/${attendee._id}`)}
                    >
                      <Td color="#000000" fontSize="12px">
                        {attendee.name}
                      </Td>
                      <Td color={attendee.team ? "#000000" : "#a0a0a0"}>
                        {attendee.team || "Unassigned"}
                      </Td>
                      <Td>{attendee.company}</Td>
                      <Td>{attendee.state}</Td>
                    </Tr>
                  ) : null}
                </>
              );
            })}
          <Tr>
            <Td>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </Td>
          </Tr>
        </TableBody>
      </Table>
    </TableScroller>
  );
}

export default AttendeesList;
