import "./Conference.css";

import SpeakerIcon from "../../assets/images/speaker.svg";
import LocationIcon from "../../assets/images/location.svg";
import ConferenceLogo from "../../assets/images/ConferenceLogo.svg";

function ConferenceCard(props) {
  return (
    <div className="conference-card">
      <div className="details">
        <h5>{props?.conference?.title || ""}</h5>
        <ul className="content">
          <li>
            <img alt="conference" src={SpeakerIcon} />
            <span>{props.conference?.hostname || ""}</span>
          </li>
          <li>
            <img alt="location" src={LocationIcon} />
            <span>{props.conference?.address || ""}</span>
          </li>
        </ul>
      </div>
      <img
        className="conference-logo"
        src={props.conference.logo || ConferenceLogo}
        alt="conference"
      />
    </div>
  );
}

export default ConferenceCard;
