import "./Attendees.css";

import Search from "../Input/Search";
import MiniHead from "../MiniHead";
import AttendeesList from "./AttendeesList";
import { useState, useEffect } from "react";

function Attendees(props) {
  const [search, setSearch] = useState("");

  const [currentTab, setCurrentTab] = useState(
    new URLSearchParams(window.location.search).get("tab")
  );

  useEffect(() => {
    setCurrentTab(new URLSearchParams(window.location.search).get("tab"));
  }, [window.location.search]);

  return (
    <div
      style={currentTab == 1 ? { overflow: "auto" } : {}}
      className={`attendees ${props.classname}`}
    >
      <Search search={search} onChange={(e) => setSearch(e.target.value)} />
      <div style={{ paddingLeft: "16px" }}>
        <MiniHead head={"Participants"} count={props.attendees.length} />
      </div>
      <AttendeesList
        filter={search}
        sort={props.sort}
        attendees={props.attendees}
      />
    </div>
  );
}

export default Attendees;
