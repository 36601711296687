import "./Conference.css";
import ArrowRight from "../../assets/images/arrow-right.svg";

function List(props) {
  return (
    <div className="list" onClick={props.click}>
      {props.icon &&
        (props.size ? (
          <img
            className="icon"
            src={props.icon}
            alt={props.label}
            width={props.size.width}
            height={props.size.height}
          />
        ) : (
          <img className="icon" src={props.icon} alt={props.label} />
        ))}
      {props.label ? <h5>{props.label}</h5> : props.children}
      {props.click && (
        <img className="nav" src={ArrowRight} alt={props.navigate} />
      )}
    </div>
  );
}

export default List;
