import "./Panel.css";

function TabButton(props) {
  return (
    <div
      onClick={props.changeTab}
      className={`tab ${props.active ? "active" : ""}`}
    >
      <div style={props.label == "My Team" ? { width: "70px" } : {}}>
        {props.label}
      </div>
    </div>
  );
}

function Panel(props) {
  return (
    <div className={`panel`} style={{ ...props.style }}>
      <div
        className="panel-tabs"
        style={{ ...props.tabstyles, overflow: "auto" }}
      >
        {props.tabs.map((tab, index) => (
          <TabButton
            key={index}
            changeTab={() => (tab.click ? tab.click() : props.changeTab(index))}
            active={tab.active}
            label={tab.label}
          />
        ))}
      </div>
      <div ref={props.parentRef} className="panel-pages">
        {props.children}
      </div>
    </div>
  );
}

export default Panel;
