function Button(props) {
  return (
    <div className={`form-button ${props.btnType}`} style={props.style} onClick={props.onClick}>
      <button>{props.name}</button>
      {props.icon && <img src={props.icon} alt={props.name} />}
    </div>
  );
}

export default Button;
