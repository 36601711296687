import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import attendeesServices from "../services/attendee.services";
import { Container, StatusBox } from "../components/Container.Style";

function ScanProfile() {
  const [status, setStatus] = useState({
    status: "progress",
    message: "Saving Profile...",
  });
  const navigate = useNavigate();
  const params = useParams("attendeeId");

  useEffect(() => {
    attendeesServices
      .saveAcquaintance(params.attendeeId)
      .then((res) => {
        setStatus({ status: "success" });
        //navigate(res.data.redirectPath);
        navigate(`/attendees/${params.attendeeId}`, {
          state: {
            note: true,
          },
        });
      })
      .catch((err) => {
        setStatus({ status: "failed", message: err.message });
      });
  }, [params.attendeeId]);

  return (
    <Container display={"flex"}>
      <StatusBox>
        <p>{status.message}</p>
        {!["success", "progress"].includes(status.status) && (
          <a href={"/Dashboard"}>Dashboard</a>
        )}
      </StatusBox>
    </Container>
  );
}

export default ScanProfile;
