function Tag(props) {
  return (
    <div className="profile-tag" onClick={props.onClick}>
      <img src={props.icon} alt={props.name} />
      <span style={props.styles} >{props.name}</span>
    </div>
  );
}

export default Tag;
