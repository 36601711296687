import { useEffect, useState } from "react";

import NoteIcon from "../../assets/images/note.svg";

import AcqInfo from "../Acquaintances/AcquaintanceInfo";
import PopupModal from "../PopupModal/PopupModal";
import Note from "../Input/Note";
import Button from "../Input/Button";

import exhibitServices from "../../services/exhibit.services";

function Exhibits(props) {
  const [progress, setProgress] = useState(false);
  const [exhibit, setExhibit] = useState({ open: false, note: "" });
  const [imageZoom, setImageZoom] = useState(false);

  const updateExhibit = () => {
    if (progress) return;
    setProgress(true);
    exhibitServices
      .updateNote(exhibit._id, { note: exhibit.note })
      .then(() => {
        props.updateExhibitNote({
          exhibitId: exhibit._id,
          note: exhibit.note,
        });
        alert("Notes Updated");
      })
      .catch((err) => {
        console.log(err.message);
        alert(err.message);
      })
      .finally(() => setProgress(false));
  };

  return (
    <div className={props.classname}>
      <div
        className="img-container"
        style={{ border: props.map ? "none" : "1px solid grey" }}
        onClick={() => props.map && setImageZoom(true)}
      >
        {props.map ? (
          <img src={props.map} alt={"map"} />
        ) : (
          <span
            style={{
              color: "grey",
              textAlign: "center",
              fontSize: "0.9rem",
              margin: "auto",
            }}
          >
            No Map Found
          </span>
        )}
      </div>

      <div className="exhibits">
        {props.exhibits.map((exh, index) => {
          return (
            <AcqInfo
              {...exh}
              hide={true}
              key={index}
              note={exh?.note?.note}
              changeNote={() =>
                setExhibit({
                  open: true,
                  _id: exh._id,
                  name: exh.name,
                  note: exh?.note?.note || "",
                })
              }
            />
          );
        })}
      </div>
      <PopupModal
        closeModal={() => setExhibit({ open: false })}
        headIcon={NoteIcon}
        headLabel={"Write a note"}
        active={exhibit.open}
      >
        <AcqInfo name={exhibit.name} company={""} hide={true} />
        <Note
          placeholder={"Start writing here"}
          limit={200}
          value={exhibit?.note || ""}
          onChange={(value) => {
            setExhibit({ ...exhibit, note: value });
          }}
        />
        <Button
          onClick={updateExhibit}
          name={progress ? "Saving..." : "Save"}
          style={{
            padding: "12px 17px",
            margin: "auto",
          }}
          btnType="loginBtn"
        />
      </PopupModal>
      <PopupModal
        closeModal={() => setImageZoom(false)}
        active={imageZoom}
        message={true}
      >
        <div
          style={{
            display: imageZoom ? "block" : "none",
            background: "transparent",
          }}
          className="img-container img-container-zoom"
        >
          <img src={props.map} alt={"map"} />
        </div>
      </PopupModal>
    </div>
  );
}

export default Exhibits;
