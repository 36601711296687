import "./Conference.css";

import TimeIcon from "../../assets/images/time.svg";
import Collab from "../../assets/images/collab.png";
import folder from "../../assets/images/folder.png";
import agenda from "../../assets/images/agenda.png";
import CalenderIcon from "../../assets/images/calender.svg";
import DoubleTickIcon from "../../assets/images/doubletick.svg";
import WebsiteIcon from "../../assets/images/website.svg";

import ConferenceCard from "./ConferenceCard";
import List from "./InfoList";
import Time from "./Time";
import Participants from "./Participants";

import { getFormattedDate } from "../../utils/date";

import { useState, useEffect } from "react";

function Conference(props) {
  const [currentTab, setCurrentTab] = useState(
    new URLSearchParams(window.location.search).get("tab")
  );

  useEffect(() => {
    setCurrentTab(new URLSearchParams(window.location.search).get("tab"));

    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has("maintab") || !urlParams.has("tab")) {
      urlParams.set("maintab", "0");
      urlParams.set("tab", "0");

      const currentPath = window.location.pathname;

      const newUrl =
        window.location.origin + currentPath + "?" + urlParams.toString();

      window.history.pushState({ path: newUrl }, "", newUrl);
    }
  }, [window.location.search]);

  return (
    <div
      className={props.classname}
      style={currentTab == 0 ? { overflow: "auto" } : {}}
    >
      <ConferenceCard
        conference={{
          title: props?.conference?.name,
          hostname: props?.conference?.hostname,
          address: props?.conference?.address,
          logo: props?.conference?.logo,
        }}
      />
      <List click={props.gotoAttendees}>
        <Participants
          totalParticipants={props.totalParticipants}
          participants={props.participants}
        />
      </List>
      <List
        icon={agenda}
        label={"Agenda"}
        click={() => props.changeMainTab(2)}
      />
      <List
        icon={Collab}
        label={"Sponsors"}
        click={() => props.changeMainTab(1)}
      />
      <List
        icon={folder}
        label={"Resources"}
        click={() => props.changeMainTab(4)}
        size={{ width: "25px", height: "25px" }}
      />
      {props.isExhibits ? (
        <List
          icon={DoubleTickIcon}
          label={"Exhibits"}
          click={() => props.changeMainTab(3)}
        />
      ) : (
        <></>
      )}
      <div
        onClick={() => {
          window.open(props?.conference?.website || "", "_blank");
        }}
        style={{ cursor: "pointer" }}
      >
        <List icon={WebsiteIcon} label={props?.conference?.website || ""} />
      </div>
      <List
        icon={CalenderIcon}
        label={`${getFormattedDate(
          props.conference.liveOn?.date,
          "/",
          "MM/DD/YY"
        )} - ${getFormattedDate(
          props.conference.endOn?.date,
          "/",
          "MM/DD/YY"
        )}`}
      />
      <List icon={TimeIcon}>
        <Time
          startDate={props?.conference?.liveOn}
          endDate={props?.conference?.endOn}
        />
      </List>
    </div>
  );
}

export default Conference;
