export const groupAgendas = (agendas) => {
  const groupedAgendas = {};
  const getDate = (date) => {
    return new Date(`${date}/${new Date().getFullYear()}`);
  };
  agendas.forEach((agenda, index) => {
    const date = getDate(agenda.date);
    const topic = {
      index: index,
      _id: agenda._id,
      topic: agenda.topic,
      attendees: agenda.attendees,
      location: agenda.location || "",
      description: agenda.description,
      time: agenda.time,
      personal: agenda.personal,
      tDate: agenda.date,
    };

    if (groupedAgendas[date.getTime()]) {
      //check type exist or not
      const typeIndex = groupedAgendas[date.getTime()].types.findIndex(
        (type) => agenda.type === type.type
      );
      if (typeIndex < 0) {
        groupedAgendas[date.getTime()].types.push({
          type: agenda.type,
          topics: [topic],
        });
      } else {
        groupedAgendas[date.getTime()].types[typeIndex].topics.push(topic);
      }
    } else {
      //insert new agenda
      groupedAgendas[date.getTime()] = {
        startDate: date.toDateString(),
        types: [
          {
            type: agenda.type,
            topics: [topic],
          },
        ],
      };
    }
  });

  return Object.keys(groupedAgendas)
    .sort()
    .map((date) => groupedAgendas[date]);
};

export const getMyAgendas = (agendas) => {
  const myagendas = agendas.filter((agenda) => {
    if (agenda.attendees && agenda.attendees[0] && agenda.attendees[0].checked)
      return true;
    else return false;
  });
  return myagendas;
};
