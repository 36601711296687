function Sponsors(props) {
  return (
    <div className={`sponsor ${props.classname}`}>
      {props.sponsors.map((sponsor, index) => {
        return (
          <div key={index} className="spr-list">
            <img className="spr-icon" src={sponsor.logo} alt={sponsor.name} />
            <h5>{sponsor.name}</h5>
          </div>
        );
      })}
    </div>
  );
}

export default Sponsors;