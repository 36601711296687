import "./Input.css";

function Note(props) {
  return (
    <div className="note">
      <textarea
        placholder={props.placeholder}
        name="note"
        value={props.value}
        onChange={(e) => {
          if (e.target.value.length > props.limit) return;
          props.onChange(e.target.value);
        }}
      />
      <span>{`${props.value.length}/${props.limit}`}</span>
    </div>
  );
}

export default Note;
