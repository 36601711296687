import "./Loader.css";

function Loader(props) {
  return (
    <div className={`loader ${!props.loading && 'hide-loader'}`}>
      <div id="circle"></div>
    </div>
  );
}

export default Loader;
