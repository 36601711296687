import { AttendeeAPIs, AuthAPIs } from "../apis";
import { get, patch, post } from "../utils/request";

async function saveAcquaintance(acquaintanceId) {
  const response = await get({
    url: AttendeeAPIs.saveAcquaintance(acquaintanceId),
  });
  if (response.status !== "success") throw response;
  return response;
}

async function getAttendees(query) {
  const response = await get({
    url: `${AttendeeAPIs.fetchAttendees}?${new URLSearchParams(
      query
    ).toString()}`,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function attendeeProfile(attendeeId) {
  const response = await get({
    url: attendeeId
      ? AttendeeAPIs.fetchAttendee(attendeeId)
      : AuthAPIs.fetchProfile,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function updateProfile(data) {
  const response = await patch({
    url: AuthAPIs.updateProfile,
    data,
    headers: {
      "content-type": "application/json",
    },
  });
  if (response.status !== "success") throw response;
  return response;
}

async function uploadPicture(picture) {
  const response = await patch({
    url: AuthAPIs.uploadPicture,
    data: picture,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function registerationStatus(attendeeId) {
  const response = await get({
    url: `${AttendeeAPIs.registerationStatus(attendeeId)}`,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function registerAttendee(attendeeId, data) {
  //remove unnecessary fields
  delete data.email;
  delete data.name;
  data.otherInformation = data.otherInformations
    .filter((info) => info.value || "-")
    .map((info) => {
      return {
        key: info.name,
        value: info.value || "-",
        accessibleTo: info.accessibleTo.map((a) => a.value),
      };
    });

  delete data.otherInformations;

  //send import request
  const response = await post({
    url: AttendeeAPIs.registerAttendee(attendeeId),
    data: data,
    headers: {
      "content-type": "application/json",
    },
  });

  if (response.status !== "success") throw response;

  return response;
}

const services = {
  saveAcquaintance,
  getAttendees,
  updateProfile,
  attendeeProfile,
  uploadPicture,
  registerAttendee,
  registerationStatus,
};

export default services;
