import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import LockIcon from "../assets/images/inputs/lock.svg";
import Logo from "../assets/images/main-logo.png";

import Input from "../components/Input/Input";
import Button from "../components/Input/Button";

import { AuthAPIs } from "../apis";
import { Container } from "../components/Container.Style";
import { FormContainer, FormBody, FormHead } from "../components/Form.Style";

function ResetPassword() {
  const navigate = useNavigate();
  const token = useRef(null);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [progress, setProgress] = useState(false);
  const [pageStatus, setPageStatus] = useState({
    status: "none",
    message: "",
  });

  const resetPassword = () => {
    if (progress) return;
    setProgress(true);
    fetch(AuthAPIs.resetPassword, {
      method: "PATCH",
      headers: {
        token: token.current,
        "content-type": "application/json",
      },
      body: JSON.stringify({ newPassword, confirmPassword }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status !== "success") throw res;
        setPageStatus({ status: "success", message: res.message });
      })
      .catch((err) => {
        setPageStatus({ status: "failed", message: err.message });
      })
      .finally(() => setProgress(false));
  };

  useEffect(() => {
    const tk = new URLSearchParams(window.location.search).get("token");
    if (!tk) {
      setPageStatus({ status: "failed", message: "Invalid Link!" });
    } else {
      token.current = tk;
    }
  }, []);

  return (
    <Container>
      <FormContainer>
        <FormHead>
          <img src={Logo} alt="main-logo" />
          <p className={pageStatus.status}>
            {pageStatus.message || "Please Enter your New Password"}
          </p>
        </FormHead>
        {["none", "failed"].includes(pageStatus.status) ? (
          <FormBody style={{ width: "100%" }}>
            <Input
              src={LockIcon}
              value={newPassword}
              type="password"
              onChange={(e) => setNewPassword(e.target.value || "")}
              placeholder="New Password"
            />
            <Input
              src={LockIcon}
              value={confirmPassword}
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value || "")}
              placeholder="Confirm Password"
            />
            <br />
            <Button
              onClick={resetPassword}
              btnType={`${progress ? "disabled" : ""} loginBtn`}
              name={progress ? "Resetting" : "Reset"}
            />

            <p className="link" onClick={() => navigate("/")}>
              Login
            </p>
          </FormBody>
        ) : (
          <FormBody>
            <p className="link" onClick={() => navigate("/")}>
              Login
            </p>
          </FormBody>
        )}
      </FormContainer>
    </Container>
  );
}

export default ResetPassword;
