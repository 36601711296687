import { useRef, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import AuthProvider from "./Auth";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Forget from "./pages/Forget";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import Attendee from "./pages/Attendee";
import Acquaintance from "./pages/Acquaintance";
import Profile from "./pages/Profile";
import ProfileEdit from "./pages/ProfileEdit";
import ScanProfile from "./pages/Scan";
import Chat from "./pages/Chat";
import Privacy from './pages/Privacy';

import LandscapeMessage from "./components/LandscapeMessage";

import analyticServices from "./services/analytics.services";
import MainForm from "./pages/MainForm";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/register/:id",
    element: <Register />,
  },
  {
    path: "/forget-password",
    element: <Forget />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/attendees/:id",
    element: <Attendee />,
  },
  {
    path: "/acquaintances/:id",
    element: <Acquaintance />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/profile/edit",
    element: <ProfileEdit />,
  },
  {
    path: "/attendees/:attendeeId/scan",
    element: <ScanProfile />,
  },
  {
    path: "/chat/:roomName",
    element: <Chat />,
  },
  {
    path: "/chat",
    element: <MainForm />,
  },
  {
    path: "/policies/privacy",
    element: <Privacy />,
  },
]);

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );

  useEffect(() => {
    const isMobileDevice =
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('Mobile') !== -1 ||
      navigator.userAgent.indexOf('Tablet') !== -1;

    setIsMobile(isMobileDevice);

    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const timeSpendRef = useRef({
    startTime: Date.now(),
  });

  function sendTrackedTime() {
    const endTime = Date.now();
    analyticServices
      .trackTime({
        startTime: timeSpendRef.current.startTime,
        endTime,
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        timeSpendRef.current.startTime = endTime;
      });
  }

  useEffect(() => {
    //start interval
    const interval = setInterval(sendTrackedTime, 4 * 60 * 1000);
    //attach listener
    const handleBeforeUnload = (event) => {
      event.preventDefault(); // This is necessary to display the browser alert message
      sendTrackedTime();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      clearInterval(interval);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    var element = document.getElementById("yourElementId");
    if (!isMobile && !isLandscape) {
      if (element) {
        element.style.display = "none";
      }
    } else {
      if (element) {
        element.style.display = "flex";
      }
    }
  }, [isMobile, isLandscape]);

  return (
    <AuthProvider>
      <RouterProvider router={router} />
      {isMobile && isLandscape && <LandscapeMessage />}
    </AuthProvider>
  );
}

export default App;
