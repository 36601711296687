import "./Input.css";
import { useEffect, useState } from "react";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";

function InputII(props) {
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState(props.type || "text");

  const handleOnChange = (e) => {
    props.onChange(e);
    if (e.target.name == "linkedIn") {
      const linkedinRegex =
        /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;
      if (!linkedinRegex.test(e.target.value)) {
        setError("Invalid LinkedIn profile link!");
      } else {
        setError("");
      }
    }
  };

  return (
    <div
      style={props.readOnly ? { opacity: 0.5 } : {}}
      className="form-input-II"
    >
      {!props.hideIcon && <img src={props.src} alt={props.label} />}
      {props.icon && props.icon}
      <div className="form-input-field" style={{ position: "relative" }}>
        <label>{props.label}</label>
        <input
          type={inputType}
          name={props.name}
          value={props.value || ""}
          onChange={(e) => {
            if (props.name == "password") {
              props.setPassword(e.target.value);
            } else {
              handleOnChange(e);
            }
          }}
          readOnly={props.readOnly}
        />
        {error != "" && (
          <div>
            <div className="Input2-error_text">{error}</div>
            <div className="Input2-error_text1">Example:</div>
            <div className="Input2-error_text2">
              https://www.linkedin.com/in/johndoe
            </div>
          </div>
        )}

        {props.name == "website" && (
          <div style={{ opacity: "0.7", fontSize: "12px" }}>
            Please enter with https://
          </div>
        )}

        {props.type == "password" ? (
          <div style={{ position: "absolute", right: "0px" }}>
            {showPassword ? (
              <FiEyeOff
                style={{
                  marginTop: "6px",
                  opacity: "0.4",
                  fontSize: "18px",
                  marginLeft: "2px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowPassword(false);
                  setInputType("password");
                }}
              />
            ) : (
              <FiEye
                style={{
                  marginTop: "6px",
                  opacity: "0.4",
                  fontSize: "18px",
                  marginLeft: "2px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowPassword(true);
                  setInputType("text");
                }}
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default InputII;
