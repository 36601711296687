function ProfileInfo(props) {
  return (
    <div className="profile-info">
      <h4>{props.head}</h4>
      <ul className="profile-details">
        {props.info.map((info, index) => {
          return (
            <>
              {info.name ? (
                <li key={index}>
                  {info.icon && info.element
                    ? info.icon
                    : info.icon && <img src={info.icon} alt={info.name} />}
                  <label>{info.name}</label>
                  <span>{info.value}</span>
                </li>
              ) : null}
            </>
          );
        })}
      </ul>
    </div>
  );
}

export default ProfileInfo;
