import { MiniHeadWrapper } from "./Container.Style";
import "./MiniHead.css";
import { BsChatLeftText } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function MiniHead(props) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/chat/${props.data.room}`, { state: props.data });
  };
  return (
    <MiniHeadWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <h5>{props.head}</h5>
          <span>{props.count}</span>
        </div>
        {props.chat != undefined && props.chat.show ? (
          <button className="chat-button" onClick={handleClick}>
            <BsChatLeftText className="chat-icon" />
          </button>
        ) : null}
      </div>
    </MiniHeadWrapper>
  );
}

export default MiniHead;
