import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Auth";

import { Container } from "../components/Container.Style";
import Header from "../components/Header";
import ProfileComponent from "../components/Profile/Profile";

import attendeeServices from "../services/attendee.services";

function Attendee() {
  const auth = useAuth();
  const params = useParams();
  const [attendee, setAttendee] = useState({});

  useEffect(() => {
    attendeeServices
      .attendeeProfile(params.id)
      .then((res) => {
        setAttendee(res.data.attendee || {});
        // console.log("invi", res.data.attendee);
      })
      .catch((err) => alert(err.message));
  }, [params.id]);

  return (
    <Container display="block">
      <Header
        child1={{
          classname: "left",
        }}
        child2={{
          classname: "center",
          head: "About",
        }}
        child3={{
          classname: "right",
        }}
        profile={auth?.user?.profile}
      />
      <ProfileComponent profile={attendee || {}} />
    </Container>
  );
}

export default Attendee;
