import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: ${(props) => props.display || "flex"};
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0;
  background: #ffffff;
  overflow-y: scroll;
`;

export const MiniHeadWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 15px 0 10px 0;
  h5 {
    font-family: GilroyMed;
    font-size: 1rem;
    color: #000000;
    margin: 5px 10px 5px 0;
  }
  span {
    background: #f7ad00;
    color: #ffffff;
    width: fit-content;
    height: fit-content;
    padding: 3px 10px;
    border-radius: 5px;
    margin: auto 0;
  }
`;

export const HeaderWrapper = styled.div`
  width: 90%;
  padding: 21px 5%;
  display: flex;
  .logo {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  .profile {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    cursor: pointer;
  }
  .head {
    font-size: 1.1rem;
    font-family: GilroyMed;
    font-weight: 600;
    color: #414141;
  }
  .hide {
    opacity: 0;
  }
  .center {
    margin: auto;
  }
  .left {
    margin: auto auto auto 0;
  }
  .right {
    margin: auto 0 auto auto;
  }
`;

export const SettingNav = styled.div`
  position: absolute;
  left: -150%;
  background: #ffffff;
  border: 0.3px solid #f1f1f1;
  border-radius: 7px;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  li {
    display: flex;
    justify-content: flex-start;
    padding: 8px 12px;
    font-size: 0.9rem;
    cursor: pointer;
  }
  li > img {
    width: 13px;
    margin-right: 5px;
  }
  li > span {
    display: block;
    height: fit-content;
    margin: auto 0;
  }
  li:nth-child(1) {
    border-bottom: 1px solid #e8e8e8;
  }
`;

export const Body = styled.div`
  width: 100%;
  height: calc(100% - 20px);
  margin-top: 20px;
  padding: 0;
  display: flex;
  background: #ffffff;
`;

export const Content = styled.div`
  flex: 1;
  padding: 1% 2%;
  overflow-y: scroll;
  background: #ffffff;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    background: #f7ad00;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #f7ad00;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  justify-content: space-between;

  .head-block {
    display: flex;
    margin: auto 0;
  }
  .head-block > h2 {
    font-family: Gilroy;
    font-size: 46px;
    font-weight: 600;
    color: #ffc727;
    margin: 0;
  }
  .head-block > h3 {
    font-size: 2rem;
    color: #616161;
    font-weight: 600;
    margin: 0;
  }

  .head-block > h4 {
    font-size: 1.75rem;
    font-weight: 500;
    margin: auto 0;
    color: #616161;
    white-space: nowrap;
  }

  .right {
    width: ${(props) => props.width || "50%"};
    justify-content: flex-end;
  }
`;

export const ContentBody = styled.div`
  width: 100%;
`;

export const PlaceHolder = styled.div`
  width: 100%;
  padding: 10% 0;
  text-align: center;
  color: #616161;
  font-size: 1.3rem;
  font-family: Gilroy;
  border-radius: 15px;
  background: rgb(241, 241, 241);
  color: rgb(170, 170, 170);
`;

export const StatusBox = styled.div`
  margin: auto;
  width: 100%;
  height: fit-content;
  padding: 10% 0;
  text-align: center;
  font-size: 1rem;
  font-family: Gilroy;
  background: transparent;

  a {
    font-size: 0.9rem;
    color: #f7ad00;
  }
`;
