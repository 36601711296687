import React from "react";

const LandscapeMessage = () => {
  return (
    <div className="landscape-message" id="landscape-message">
      <div>
        Please rotate your device to portrait mode and please reload the page.
      </div>
    </div>
  );
};

export default LandscapeMessage;
