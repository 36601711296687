import "./Profile.css";
import { useLocation, useNavigate } from "react-router-dom";

import MessageIcon from "../../assets/images/profile/message-III.svg";
import LinkedInIcon from "../../assets/images/profile/linkedIn-III.svg";
import PhoneIcon from "../../assets/images/profile/phone-III.svg";

import CoverProfile from "./CoverProfile";
import ProfileInfo from "./ProfileInfo";
import Description from "./Description";
import { useEffect, useRef, useState } from "react";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import Note from "../Input/Note";
import PopupModal from "../PopupModal/PopupModal";

import NoteIcon from "../../assets/images/note.svg";

import acquaintanceServices from "../../services/acquaintance.services";

import AcqInfo from "../Acquaintances/AcquaintanceInfo";

import Button from "../Input/Button";

import { useAuth } from "../../Auth";

function ProfileComponent(props) {
  const location = useLocation();
  const noteCheck = location.state?.note;
  const currentPath = window.location.pathname;
  const parts = currentPath.split("/");
  const attendeeId = parts[parts.length - 1];
  const user = JSON.parse(localStorage.getItem("user"));
  const [acquaintance, setAcquaintance] = useState({ editor: false });
  const [acquaintances, setAcquaintances] = useState([]);
  const [progress, setProgress] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (noteCheck) {
      acquaintanceServices
        .getAcquaintances()
        .then((res) => {
          setAcquaintances(res.data.acquaintances);
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  }, [noteCheck]);

  function openNote(id, userId) {
    const acq = acquaintances.find(
      (acq) => acq.attendeeId._id == id && acq.userId == userId
    );
    if (!acq) return;
    setAcquaintance({ ...acq, editor: true });
  }

  function syncNote(id, note) {
    const acqIndex = acquaintances.findIndex(
      (acq) => acq.attendeeId._id == attendeeId && acq.userId == user._id
    );
    if (acqIndex < 0) return;
    acquaintances[acqIndex].note = note;
    setAcquaintances([...acquaintances]);
  }

  function updateAcquaintance() {
    if (progress) return;
    setProgress(false);
    acquaintanceServices
      .updateAcquaintance(acquaintance._id, { note: acquaintance.note })
      .then(() => {
        //update local state
        syncNote(acquaintance._id, acquaintance.note);
        setAcquaintance({ editor: false });
        setProgress(false);
        auth.setMessagePopup({
          message: `${acquaintance.attendeeId?.name || ""} Note Saved!`,
          status: "success",
        });
      })
      .catch((err) => {
        auth.setMessagePopup({
          message: err.message,
          status: "failed",
        });
      });
  }

  const getProfile = (platform) => {
    return (
      (props.profile?.socialLinks &&
        props.profile.socialLinks.find((s) => s.platform === platform)
          ?.profile) ||
      undefined
    );
  };

  const labels = useRef({
    state: "State",
    // company: "Company",
    businessType: "Business Type",
    jobTitle: "Job Title",
    jobType: "Job Type",
    jobLevel: "Job Level",
    role: "Role",
    priorAttendance: "Your prior WCF attendance",
    interactionStyle: "Your interaction style",
    // teamExperience: "Team experience preference*",
    considerYourself: "Do you consider yourself an*",
  });

  return (
    <>
      <CoverProfile
        note={{
          noteCheck: noteCheck,
          openNote: () => {
            openNote(attendeeId, user._id);
          },
        }}
        profile={props?.profile?.profile}
        name={props?.profile?.name}
        email={
          props.profile?.otherInformation &&
          props.profile?.otherInformation.find((p) => p.key == "email")
            ? props.profile?.email
            : null
        }
        phone={
          props.profile?.otherInformation &&
          props.profile?.otherInformation.find((p) => p.key == "contact")
            ? props.profile?.contact
            : null
        }
        linkedIn={getProfile("linkedIn")}
        // company={
        //   props.profile?.otherInformation
        //     ? props.profile.otherInformation.find(
        //         (obj) => obj["key"] == "company"
        //       )?.value
        //     : ""
        // }
        company={props.profile.company}
        //website={getProfile("website")}
      />
      <Description
        // company={props.profile?.company || ""}
        designation={props.profile?.designation || ""}
        description={props.profile?.description || ""}
        lastTag={props.lastTag}
      />
      {/* <ProfileInfo
        head={"Contact Information"}
        info={[
          {
            icon: LinkedInIcon,
            name: "LinkedIn",
            value: getProfile("linkedIn"),
          },
          { icon: MessageIcon, name: "Message", value: props.profile.email },
          {
            icon: PhoneIcon,
            name: "Phone",
            value: props.profile?.contact || "",
          },
          {
            icon: <HiOutlineBuildingOffice style={{opacity: "0.5", fontSize: "18px", }} />,
            element: true,
            name: <div style={{paddingLeft:"9px", paddingTop:"2.2px"}}>Company</div>,
            value: props.profile?.otherInformation
              ? props.profile.otherInformation.find(
                  (obj) => obj["key"] == "company"
                )?.value
              : "",
          },
        ]}
      /> */}

      <PopupModal
        closeModal={() => setAcquaintance({ editor: false })}
        headIcon={NoteIcon}
        headLabel={"Write a note"}
        active={acquaintance.editor}
      >
        <AcqInfo
          profile={acquaintance.attendeeId?.profile?.fileurl}
          name={acquaintance.attendeeId?.name}
          company={acquaintance.attendeeId?.company}
          click={() => navigate(`/acquaintances/${acquaintance._id}`)}
          hideNote={true}
        />
        <Note
          placeholder={"Start writing here"}
          limit={200}
          value={acquaintance.note || ""}
          onChange={(value) => {
            setAcquaintance({ ...acquaintance, note: value });
          }}
        />
        <Button
          onClick={updateAcquaintance}
          name={progress ? "Saving..." : "Save"}
          style={{
            padding: "12px 17px",
            margin: "auto",
          }}
          btnType="loginBtn"
        />
      </PopupModal>
      <ProfileInfo
        head={"Other Information"}
        info={
          (props.profile?.otherInformation &&
            props.profile.otherInformation.map((info) => {
              return {
                name: labels.current[info.key],
                value: info.value,
              };
            })) ||
          []
        }
      />
    </>
  );
}

export default ProfileComponent;
