import { useState, useEffect } from "react";

import { get24Time } from "../../utils/date";

function Time(props) {
  const [status, setStatus] = useState({
    id: "not-started",
    label: "-",
    time: "-",
  });

  function updateStatus(liveOn, closeOn) {
    const start = liveOn?.date && new Date(liveOn.date);
    const end = closeOn?.date && new Date(closeOn.date);
    const status = {};

    if (!start || !end) {
      status.id = "Not-started";
      status.label = "-";
      status.time = `-`;
      setStatus({ ...status });
      return;
    }

    if (start.getTime() > Date.now()) {
      status.id = "not-started";
      status.label = "Yet To Begin";
      status.time = `Live At ${get24Time(liveOn.time)}`;
      setStatus({ ...status });
      return;
    }

    if (start.getTime() < Date.now() && Date.now() < end.getTime()) {
      status.id = "open";
      status.label = "Live";
      status.time = `End At ${get24Time(closeOn.time)}`;
      setStatus({ ...status });
      return;
    }

    if (end.getTime() < Date.now()) {
      status.id = "closed";
      status.label = "Ended";
      status.time = `Ended At ${get24Time(closeOn.time)}`;
      setStatus({ ...status });
      return;
    }
  }

  useEffect(() => {
    if (!props.startDate || !props.endDate) return;
    updateStatus(props.startDate, props.endDate);
  }, [props.startDate, props.endDate]);

  return (
    <div className="conference-time">
      <label className={`status ${status?.id}`}>{status?.label}</label>
      <label className="dot"></label>
      <label>{status.time}</label>
    </div>
  );
}

export default Time;
