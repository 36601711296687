import axios from "axios";

function redirectToLogin() {
  window.location.href = `/?redirect=${window.location.pathname}`;
}

function responseHandler(res, customHandler) {
  if (customHandler) {
    //custom response handler
    return customHandler(res);
  } else {
    //default response handler
    if (res.data.status === "unauthorized") {
      window.localStorage.removeItem("token");
      redirectToLogin();
    }
    return res.data;
  }
}

export async function get({ url, headers = {}, options, customResHandler }) {
  const res = await axios.get(url, {
    headers: {
      ...headers,
      token: window.localStorage.getItem("token"),
    },
    validateStatus: () => true,
    ...options,
  });
  return responseHandler(res, customResHandler);
}

export async function post({
  url,
  headers = {},
  options,
  data,
  customResHandler,
}) {
  const res = await axios.post(url, data, {
    headers: {
      ...headers,
      token: window.localStorage.getItem("token"),
    },
    validateStatus: () => true,
    ...options,
  });
  return responseHandler(res, customResHandler);
}

export async function patch({
  url,
  headers = {},
  options,
  data,
  customResHandler,
}) {
  const res = await axios.patch(url, data, {
    headers: {
      ...headers,
      token: window.localStorage.getItem("token"),
    },
    validateStatus: () => true,
    ...options,
  });
  return responseHandler(res, customResHandler);
}

export async function remove({ url, headers = {}, options, customResHandler }) {
  const res = await axios.delete(url, {
    headers: {
      ...headers,
      token: window.localStorage.getItem("token"),
    },
    validateStatus: () => true,
    ...options,
  });
  return responseHandler(res, customResHandler);
}
