import { ConferenceAPIs } from "../apis";
import { get, post, patch, remove } from "../utils/request";

async function getConference(conferenceId) {
  const response = await get({
    url: ConferenceAPIs.fetchConference(conferenceId),
  });
  if (response.status !== "success") throw response;
  return response;
}

async function fetchAgendas(conferenceId) {
  const response = await get({
    url: ConferenceAPIs.fetchAgendas(conferenceId),
  });
  if (response.status !== "success") throw response;
  return response;
}

async function addAgenda(conferenceId, data) {
  if (
    !data.agendaDate ||
    !data.startTime ||
    !data.endTime ||
    !data.type ||
    !data.topic ||
    !data.description
  )
    throw { message: "Please fill all fields" };
  const response = await post({
    url: ConferenceAPIs.addAgenda(conferenceId),
    data,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function updateAgenda(conferenceId, agendaId, data) {
  const response = await patch({
    url: ConferenceAPIs.updateAgenda(conferenceId, agendaId),
    data,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function removeAgenda(conferenceId, agendaId) {
  const response = await remove({
    url: ConferenceAPIs.removeAgenda(conferenceId, agendaId),
  });
  if (response.status !== "success") throw response;
  return response;
}

const services = {
  getConference,
  fetchAgendas,
  addAgenda,
  updateAgenda,
  removeAgenda,
};

export default services;
