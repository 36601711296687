import { FormBody } from "../Form.Style";
import InputII from "../Input/Input-II";
import UserIcon from "../../assets/images/inputs/user-II.svg";
import { RiLockPasswordLine } from "react-icons/ri";
import MailIcon from "../../assets/images/inputs/mail-II.svg";
import PhoneIcon from "../../assets/images/inputs/phone-II.svg";
import PencilIcon from "../../assets/images/inputs/pencil.svg";
import SelectorInput from "../Input/SelectorInput";
import { useRef } from "react";

function ProfileInfo(props) {
  const handleInput = (e) => {
    props.profile[e.target.name] = e.target.value;
    props.setProfile({ ...props.profile });
  };

  const otherInformations = useRef([
    {
      name: "email",
      label: "Email",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
    },
    {
      name: "contact",
      label: "Phone",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
    },
  ]);
  const options = useRef([
    { label: "Only Me", value: "only me" },
    { label: "Acquaintance", value: "acquaintance" },
    { label: "Team", value: "team" },
    { label: "Everyone", value: "everyone" },
  ]);

  const handleSelectorInput = (e) => {
    if (!props.profile.otherInformation) return;
    const index = props.profile?.otherInformation.findIndex(
      (info) => info.key === e.target.name
    );
    if (index < 0) {
      props.profile.otherInformation.push({
        key: e.target.name,
        value: e.target.value,
        accessibleTo: ["only me"],
      });
    } else props.profile.otherInformation[index].value = e.target.value;
    props.setProfile({ ...props.profile });
  };

  const selectAccessibility = (option, name) => {
    if (!props.profile?.otherInformation) return;
    const index = props.profile?.otherInformation.findIndex(
      (info) => info.key === name
    );
    if (index < 0) {
      props.profile.otherInformation.push({
        key: name,
        value: "",
        accessibleTo: option.value === "only me" ? ["only me"] : [option.value],
      });
      props.setProfile({ ...props.profile });
      return;
    }
    //remove if option is already selected;
    const selectedLength =
      props.profile?.otherInformation[index].accessibleTo.length;
    const accessibleTo = props.profile?.otherInformation[
      index
    ].accessibleTo.filter((ac) => ac !== option.value);
    // console.log(accessibleTo);
    //add new option if selected option is new
    if (accessibleTo.length === selectedLength) {
      switch (option.value) {
        case "everyone":
          props.profile.otherInformation[index].accessibleTo = [
            option.value,
            // "acquaintance",
            // "team",
          ];
          break;
        case "only me":
          props.profile.otherInformation[index].accessibleTo = [option.value];
          break;
        default:
          console.log(option.value);
          const acs = accessibleTo.filter(
            (ac) => !["everyone", "only me"].includes(ac)
          );

          acs.push(option.value);

          props.profile.otherInformation[index].accessibleTo = acs;
          break;
      }
    } else {
      if (!accessibleTo.length) accessibleTo.push("only me");
      let acs = accessibleTo.filter(
        (ac) => !["everyone", "only me"].includes(ac)
      );

      if (acs.includes(option.value)) {
        acs.filter((item) => item != option.value);
      }

      if (acs.length == 0) {
        acs.push("only me");
      }

      props.profile.otherInformation[index].accessibleTo = acs;
    }

    props.setProfile({ ...props.profile });
  };

  function getValue(name) {
    return props.profile[name];
    // if (!props.profile.otherInformation) return;
    // const index = props.profile?.otherInformation.findIndex(
    //   (info) => info.key === name
    // );
    // if (index < 0) return "";
    // return props.profile.otherInformation[index].value;
  }

  function getSelectedValues(name) {
    const defaultValue = [{ label: "Only Me", value: "only me" }];
    if (!props.profile?.otherInformation) return defaultValue;
    const index = props.profile?.otherInformation.findIndex(
      (info) => info.key === name
    );
    if (index < 0) return defaultValue;
    const info = props.profile.otherInformation[index];
    const option = {
      "only me": "Only Me",
      acquaintance: "Acquaintance",
      team: "Team",
      everyone: "Everyone",
    };
    return info.accessibleTo.map((acc) => {
      return { label: option[acc], value: acc };
    });
  }

  return (
    <div className="profile-container">
      <h4>Personal Information</h4>
      <FormBody>
        <InputII
          style={{ border: "1px solid #D9D9D9" }}
          key={1}
          label={"Name"}
          name={"name"}
          src={UserIcon}
          value={props.profile.name}
          type="text"
          readOnly={true}
        />
        <FormBody>
          <SelectorInput
            style={{ border: "1px solid #D9D9D9" }}
            key="email"
            value={getValue("email")}
            type="text"
            label={"Email"}
            name={"email"}
            selectedValues={getSelectedValues("email")}
            selectValue={selectAccessibility}
            options={options.current}
            readOnly={true}
            onChange={handleInput}
          />
        </FormBody>

        {/* <InputII
          style={{ border: "1px solid #D9D9D9" }}
          key={2}
          label={"Email"}
          name={"email"}
          src={MailIcon}
          value={props.profile.email}
          type="text"
          readOnly={true}
        /> */}
        {/* <InputII
          style={{ border: "1px solid #D9D9D9" }}
          key={3}
          label={"Phone"}
          name={"contact"}
          src={PhoneIcon}
          value={props.profile.contact}
          type="text"
          onChange={handleInput}
        /> */}
        <FormBody>
          <SelectorInput
            style={{ border: "1px solid #D9D9D9" }}
            key="contact"
            value={getValue("contact")}
            type="text"
            label={"Phone"}
            name={"contact"}
            selectedValues={getSelectedValues("contact")}
            selectValue={selectAccessibility}
            options={options.current}
            readOnly={false}
            onChange={handleInput}
          />
        </FormBody>
        <InputII
          style={{ border: "1px solid #D9D9D9" }}
          key={1}
          label={"Password"}
          name={"password"}
          icon={
            <RiLockPasswordLine
              style={{
                marginTop: "6px",
                opacity: "0.4",
                fontSize: "18px",
                marginLeft: "2px",
              }}
            />
          }
          value={props.password}
          setPassword={props.setPassword}
          type="password"
          readOnly={false}
          hideIcon={true}
        />
        <InputII
          style={{ border: "1px solid #D9D9D9" }}
          key={1}
          label={"Confirm Password"}
          name={"password"}
          icon={
            <RiLockPasswordLine
              style={{
                marginTop: "6px",
                opacity: "0.4",
                fontSize: "18px",
                marginLeft: "2px",
              }}
            />
          }
          value={props.confirmPassword}
          setPassword={props.setConfirmPassword}
          type="password"
          readOnly={false}
          hideIcon={true}
        />
        {/* <InputII
          style={{ border: "1px solid #D9D9D9" }}
          key={4}
          label={"Pencil"}
          name={"description"}
          src={PencilIcon}
          value={props.profile.description}
          type="text"
          onChange={handleInput}
        /> */}
      </FormBody>
    </div>
  );
}

export default ProfileInfo;
