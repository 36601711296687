const ORIGIN = "https://api.networkingtag.com";
// const ORIGIN = "http://127.0.0.1:5003";



export const AuthAPIs = {
  login: `${ORIGIN}/api/v1/auth/login?role=attendee`,
  validateToken: `${ORIGIN}/api/v1/auth/validate`,
  fetchProfile: `${ORIGIN}/api/v1/auth/fetch-profile`,
  updateProfile: `${ORIGIN}/api/v1/auth/attendee-profile`,
  getSignedURL: `${ORIGIN}/api/v1/auth//s3-signed-url`,
  uploadPicture: `${ORIGIN}/api/v1/auth/picture`,
  forgetPassword: `${ORIGIN}/api/v1/auth/forget-password`,
  resetPassword: `${ORIGIN}/api/v1/auth/reset-password`,
};

export const ChatAPIs = {
  save: `${ORIGIN}/api/v1/chat/save`,
  getHistory: (room) => `${ORIGIN}/api/v1/chat/history/${room}`,
};

export const CalendarAPIs = {
  sendCalendarEvent:`${ORIGIN}/api/v1/calendar/send`,
};


export const ConferenceAPIs = {
  fetchConference: (id) => `${ORIGIN}/api/v1/conferences/${id}`,
  updateConference: (id) => `${ORIGIN}/api/v1/conferences/${id}`,
  fetchAgendas: (id) => `${ORIGIN}/api/v1/conferences/${id}/agendas`,
  addAgenda: (id) => `${ORIGIN}/api/v1/conferences/${id}/agendas`,
  updateAgenda: (conferenceId, agendaId) =>
    `${ORIGIN}/api/v1/conferences/${conferenceId}/agendas/${agendaId}/myagenda`,
  removeAgenda: (conferenceId, agendaId) =>
    `${ORIGIN}/api/v1/conferences/${conferenceId}/agendas/${agendaId}`,
};

export const AttendeeAPIs = {
  saveAcquaintance: (id) => `${ORIGIN}/api/v1/attendees/${id}/save`,
  fetchAttendees: `${ORIGIN}/api/v1/attendees`,
  fetchAttendee: (id) => `${ORIGIN}/api/v1/attendees/${id}`,
  registerationStatus: (id) => `${ORIGIN}/api/v1/attendees/${id}/registeration`,
  registerAttendee: (id) => `${ORIGIN}/api/v1/attendees/${id}/register`,
};

export const AcquaintanceAPIs = {
  fetchAcquaintances: `${ORIGIN}/api/v1/acquaintances`,
  fetchAcquaintance: (id) => `${ORIGIN}/api/v1/acquaintances/${id}`,
  updateAcquaintance: (id) => `${ORIGIN}/api/v1/acquaintances/${id}`,
};

export const ExhibitAPIs = {
  fetchExhibits: (id) => `${ORIGIN}/api/v1/exhibits?conferenceId=${id}`,
  addNoteOnExhibit: (id) => `${ORIGIN}/api/v1/exhibits/${id}/note`,
};

export const AnalyticAPIs = {
  trackTime: `${ORIGIN}/api/v1/analytics/track-time`,
};
