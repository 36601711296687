import { SearchWrapper } from "./input.style";
import SearchIcon from "../../assets/images/search.svg";
function Search(props) {
  return (
    <SearchWrapper width={props.width}>
      <img src={SearchIcon} alt="search" />
      <input
        name="search"
        placeholder="Search Here..."
        value={props.search}
        onChange={props.onChange}
      />
    </SearchWrapper>
  );
}

export default Search;
