import { useNavigate } from "react-router-dom";

import DefaultIcon from "../../assets/images/default-avatar.jpg";

function Participant(props) {
  return (
    <div
      style={{ left: `${props.index * -10}px` }}
      onClick={props.navigate}
      className="participant"
    >
      <img src={props.profile || DefaultIcon} alt={props.name} />
    </div>
  );
}

function Participants(props) {
  const navigate = useNavigate();
  return (
    <div className="conference-participants">
      <div className="p-profiles">
        {props.participants.map((participant, index) => {
          return (
            <Participant
              key={index}
              index={index}
              id={participant._id}
              name={participant.name}
              profile={participant?.profile?.fileurl}
              navigate={() => navigate(`/attendees/${participant._id}`)}
            />
          );
        })}
      </div>
      <div className="p-counts">
        {props.totalParticipants || 0} Participants
      </div>
    </div>
  );
}

export default Participants;
