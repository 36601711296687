import styled from "styled-components";

export const SearchWrapper = styled.div`
  width: ${(props) => props.width || "100%"};
  background: #f8f8f8;
  display: flex;
  border-radius: 5px;
  img {
    width: 18px;
    height: 18px;
    margin: auto 10px auto 15px;
    background: transparent;
  }
  input {
    width: 100%;
    padding: 15px 5px;
    font-size: 0.9rem;
    background: transparent;
    border: transparent;
    color: #b8b8b8;
    outline-style: none;
    margin: auto 0;
    :placeholder {
      color: #b8b8b8;
      font-size: 1rem;
    }
  }
`;
