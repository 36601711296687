import { useState } from "react";
import { useNavigate } from "react-router-dom";

import MiniLogo from "../assets/images/mini-logo.svg";
import DefaultLogo from "../assets/images/default-avatar.jpg";
import UserIcon from "../assets/images/user-III.svg";
import LogoutIcon from "../assets/images/sign-out.svg";

import { HeaderWrapper, SettingNav } from "./Container.Style";

function Setting(props) {
  const [show, setShow] = useState(false);
  return (
    <div style={{ position: "relative" }} className={`${props.className}`}>
      <img
        onClick={() => setShow(!show)}
        className="profile"
        src={props.profileImage}
        alt={"user"}
      />
      {show && (
        <SettingNav>
          <li onClick={props.profileLink}>
            <img src={UserIcon} alt="profile" />
            <span>Profile</span>
          </li>
          <li
            id="logout"
            onClick={() => {
              window.localStorage.removeItem("token");
              props.logoutNav();
            }}
          >
            <img src={LogoutIcon} alt="logout" />
            <span>Logout</span>
          </li>
        </SettingNav>
      )}
    </div>
  );
}

function Header(props) {
  const navigate = useNavigate();
  return (
    <HeaderWrapper>
      <img
        onClick={() => navigate("/dashboard")}
        className={`logo ${props?.child1?.classname}`}
        src={MiniLogo}
        alt="mini logo"
      />
      {props?.child2?.head ? (
        <p className="head">{props?.child2?.head}</p>
      ) : (
        <img
          onClick={() => navigate("/dashboard")}
          className={`logo ${props?.child2?.classname}`}
          src={MiniLogo}
          alt="mini logo"
        />
      )}
      {props?.child3?.head ? (
        <p className="label">{props?.child3?.head}</p>
      ) : (
        <Setting
          className={props?.child3?.classname}
          profileImage={props?.profile?.fileurl || DefaultLogo}
          profileLink={() => navigate("/profile")}
          logoutNav={() => navigate("/")}
        />
      )}
    </HeaderWrapper>
  );
}

export default Header;
