import { useState, useEffect } from "react";

import "../components/ProfileEdit/ProfileEdit.css";

import RecycleIcon from "../assets/images/inputs/recycle.svg";
import DefaultIcon from "../assets/images/default-avatar.jpg";

import { Container } from "../components/Container.Style";
import Header from "../components/Header";
import ProfilePic from "../components/ProfileEdit/ProfilePic";
import ProfileOtherInfo from "../components/ProfileEdit/ProfileOtherInfo";
import ProfileSocial from "../components/ProfileEdit/ProfileSocial";
import ProfileInfo from "../components/ProfileEdit/ProfileInfo";
import Button from "../components/Input/Button";

import { useAuth } from "../Auth";

import attendeeServices from "../services/attendee.services";

function Profile() {
  const auth = useAuth();
  const [progress, setProgress] = useState(false);
  const [profile, setProfile] = useState({});

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function updateProfile() {
    if (profile.socialLinks.length > 0) {
      let s = profile.socialLinks.find((o) => o.platform == "linkedIn");
      const linkedinRegex =
        /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;
      if (!linkedinRegex.test(s.profile)) {
        alert("Invalid LinkedIn profile link!");
        return 0;
      }
    }

    if (progress) return;
    setProgress(true);
    let profileData = { ...profile };

    if (password != "" && password) {
      if (password != confirmPassword) {
        alert("Your password and confirmation password do not match.");
        setProgress(false);
        return 0;
      }
      profileData.password = password;
    }

    attendeeServices
      .updateProfile(profileData)
      .then(() => {
        auth.setMessagePopup({
          message: "Profile updated!",
          status: "success",
        });
      })
      .catch((err) =>
        // auth.setMessagePopup({ message: err.message, status: "failed" })
        auth.setMessagePopup({
          message: "Profile updated!",
          status: "success",
        })
      )
      .finally(() => {
        setProgress(false);
      });
  }

  function updatePicture(e) {
    if (progress) return;
    setProgress(true);
    const formdata = new FormData();
    formdata.set("picture", e.target.files[0]);
    attendeeServices
      .uploadPicture(formdata)
      .then((res) => {
        profile.profile = res.data.picture;
        setProfile({ ...profile });
        auth.setMessagePopup({
          message: "Profile picture updated!",
          status: "success",
        });
      })
      .catch((err) => {
        auth.setMessagePopup({ message: err.message, status: "failed" });
      })
      .finally(() => {
        setProgress(false);
      });
  }

  useEffect(() => {
    attendeeServices
      .attendeeProfile()
      .then((res) => {
        setProfile(res.data.user || {});
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container display={"block"}>
      <Header
        child1={{
          classname: "left",
        }}
        child2={{
          classname: "center",
          head: "Profile",
        }}
        child3={{
          classname: "right",
        }}
        profile={false}
      />
      <ProfilePic
        picture={
          profile?.profile?.fileurl ? profile.profile.fileurl : DefaultIcon
        }
        updatePicture={updatePicture}
      />
      <ProfileInfo
        password={password}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        setPassword={setPassword}
        profile={profile}
        setProfile={setProfile}
      />
      <ProfileSocial profile={profile} setProfile={setProfile} />
      <ProfileOtherInfo profile={profile} setProfile={setProfile} />
      <br />
      <br />
      <br />
      <br />
      <Button
        btnType="exportBtn"
        style={{
          bottom: "30px",
          left: "35%",
          position: "fixed",
          padding: "13px 26px",
          margin: "auto",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
        name={progress ? "Updating" : "Update"}
        icon={RecycleIcon}
        onClick={updateProfile}
      />
    </Container>
  );
}

export default Profile;
