function Social(props) {
  return (
    <div className={`social-link ${props.label ? "label-round" : "round"}`}>
      <a
        href={props.profile}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {typeof props.icon == "string" ? (
          <img src={props.icon} alt={props.platform} />
        ) : (
          props.icon
        )}
      </a>
      <div className="social-link-label">{props.label && props.label}</div>
    </div>
  );
}

export default Social;
