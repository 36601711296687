import { useRef } from "react";
import { FormBody } from "../Form.Style";
import SelectorInput from "../Input/SelectorInput";
import { useEffect } from "react";
import InputII from "../Input/Input-II";


function ProfileOtherInfo(props) {
  const otherInformations = useRef([
    // {
    //   name: "company",
    //   label: "Company",
    //   value: "",
    //   accessibleTo: [{ value: "everyone", label: "Everyone" }],
    //   disableSelector: true,
    // },
    {
      name: "state",
      label: "State",
      value: "",
      accessibleTo: [{ value: "everyone", label: "Everyone" }],
      disableSelector: true,
    },
    {
      name: "businessType",
      label: "Business Type",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
      selectOptions: [
        "Bank/Financial Institution",
        "BPO/Service Provider",
        "Consultant",
        "Consumer Product/Service/Utility/Healthcare",
        "Debt Buyer",
        "Digital Lender (Fintech)",
        "Government",
        "Law Firm",
        "Technology Provider",
        "Other",
      ],
    },
    {
      name: "jobTitle",
      label: "Job Title",
      value: "",
      accessibleTo: [{ value: "everyone", label: "Everyone" }],
      disableSelector: true,
    },
    // {
    //   name: "jobType",
    //   label: "Job Type",
    //   value: "",
    //   accessibleTo: [{ value: "only me", label: "Only Me" }],
    // },
    {
      name: "jobLevel",
      label: "Job Level",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
      selectOptions: [
        "Corporate Office/Executive",
        "VP/Division Head",
        "Manager/Department Lead",
        "Professional",
        "Coordinator/Assistant",
        "Other",
      ],
    },
    {
      name: "role",
      label: "Role",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
      selectOptions: [
        "Customer Relations",
        "Finance/Accounting",
        "Credit Risk & Underwriting",
        "General Executive Management",
        "Legal",
        "Marketing",
        "Operations",
        "Product Management",
        "Risk & Compliance",
        "Sales & Business Development",
        "Technology",
        "Other",
      ],
    },
    {
      name: "priorAttendance",
      label: "Your prior WCF attendance",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
      selectOptions: [
        "I've attended in person",
        "I've only attended virtually",
        "My first time",
      ],
    },
    {
      name: "interactionStyle",
      label: "Your interaction style",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
      selectOptions: [
        "Primarily Introvert",
        "Primarily Extrovert",
        "A true balance of both",
      ],
    },
    // {
    //   name: "teamExperience",
    //   label: "Team experience preference*",
    //   value: "",
    //   accessibleTo: [{ value: "only me", label: "Only Me" }],
    // },
    // {
    //   name: "considerYourself",
    //   label: "Do you consider yourself an*",
    //   value: "",
    //   accessibleTo: [{ value: "only me", label: "Only Me" }],
    // },
  ]);
  const options = useRef([
    { label: "Only Me", value: "only me" },
    { label: "Acquaintance", value: "acquaintance" },
    { label: "Team", value: "team" },
    { label: "Everyone", value: "everyone" },
  ]);

  const handleSelectorInput = (e) => {
    if (!props.profile.otherInformation) return;
    const index = props.profile?.otherInformation.findIndex(
      (info) => info.key === e.target.name
    );
    if (index < 0) {
      props.profile.otherInformation.push({
        key: e.target.name,
        value: e.target.value,
        accessibleTo: ["only me"],
      });
    } else props.profile.otherInformation[index].value = e.target.value;
    props.setProfile({ ...props.profile });
  };

  const selectAccessibility = (option, name) => {
    if (!props.profile?.otherInformation) return;
    const index = props.profile?.otherInformation.findIndex(
      (info) => info.key === name
    );
    if (index < 0) {
      props.profile.otherInformation.push({
        key: name,
        value: "",
        accessibleTo: option.value === "only me" ? ["only me"] : [option.value],
      });
      props.setProfile({ ...props.profile });
      return;
    }
    //remove if option is already selected;
    const selectedLength =
      props.profile?.otherInformation[index].accessibleTo.length;
    const accessibleTo = props.profile?.otherInformation[
      index
    ].accessibleTo.filter((ac) => ac !== option.value);
    // console.log(accessibleTo);
    //add new option if selected option is new
    if (accessibleTo.length === selectedLength) {
      switch (option.value) {
        case "everyone":
          props.profile.otherInformation[index].accessibleTo = [
            option.value,
            // "acquaintance",
            // "team",
          ];
          break;
        case "only me":
          props.profile.otherInformation[index].accessibleTo = [option.value];
          break;
        default:
          console.log(option.value);
          const acs = accessibleTo.filter(
            (ac) => !["everyone", "only me"].includes(ac)
          );

          acs.push(option.value);

          props.profile.otherInformation[index].accessibleTo = acs;
          break;
      }
    } else {
      if (!accessibleTo.length) accessibleTo.push("only me");
      let acs = accessibleTo.filter(
        (ac) => !["everyone", "only me"].includes(ac)
      );

      if (acs.includes(option.value)) {
        acs.filter((item) => item != option.value);
      }

      if (acs.length == 0) {
        acs.push("only me");
      }

      props.profile.otherInformation[index].accessibleTo = acs;
    }

    props.setProfile({ ...props.profile });
  };

  function getValue(name) {
    if (!props.profile.otherInformation) return;
    const index = props.profile?.otherInformation.findIndex(
      (info) => info.key === name
    );
    if (index < 0) return "";
    return props.profile.otherInformation[index].value;
  }

  function getSelectedValues(name) {
    const defaultValue = [{ label: "Only Me", value: "only me" }];
    if (!props.profile?.otherInformation) return defaultValue;
    const index = props.profile?.otherInformation.findIndex(
      (info) => info.key === name
    );
    if (index < 0) return defaultValue;
    const info = props.profile.otherInformation[index];
    const option = {
      "only me": "Only Me",
      acquaintance: "Acquaintance",
      team: "Team",
      everyone: "Everyone",
    };
    return info.accessibleTo.map((acc) => {
      return { label: option[acc], value: acc };
    });
  }

  const handleInput = (e) => {
    props.profile[e.target.name] = e.target.value;
    props.setProfile({ ...props.profile });
  };

  return (
    <div className="profile-container">
      <h4>Other Information</h4>
      <FormBody>
        <InputII
          style={{ border: "1px solid #D9D9D9" }}
          key="company"
          value={props.profile.company}
          type="text"
          label={"Company"}
          name={"company"}
          hideIcon={true}
          selectedValues={getSelectedValues("company")}
          selectValue={selectAccessibility}
          options={options.current}
          readOnly={false}
          onChange={handleInput}
        />
        {otherInformations.current.map((info, index) => {
          return (
            <SelectorInput
              selectOptions={info.selectOptions ? info.selectOptions : null}
              style={{ border: "1px solid #D9D9D9" }}
              key={index}
              value={getValue(info.name)}
              type="text"
              label={info.label}
              name={info.name}
              onChange={handleSelectorInput}
              selectedValues={getSelectedValues(info.name)}
              selectValue={selectAccessibility}
              options={options.current}
              disableSelector={
                info.disableSelector ? info.disableSelector : false
              }
            />
          );
        })}
      </FormBody>
    </div>
  );
}

export default ProfileOtherInfo;
