import { AcquaintanceAPIs } from "../apis";
import { get, patch } from "../utils/request";

async function getAcquaintances() {
  const response = await get({
    url: AcquaintanceAPIs.fetchAcquaintances,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function getAcquaintance(acquaintanceId) {
  const response = await get({
    url: AcquaintanceAPIs.fetchAcquaintance(acquaintanceId),
  });
  if (response.status !== "success") throw response;
  return response;
}

async function updateAcquaintance(acquaintanceId, data) {
  const response = await patch({
    url: AcquaintanceAPIs.updateAcquaintance(acquaintanceId),
    data: data,
    headers: {
      "content-type": "application/json",
    },
  });
  if (response.status !== "success") throw response;
  return response;
}

const services = {
  getAcquaintances,
  getAcquaintance,
  updateAcquaintance,
};

export default services;
