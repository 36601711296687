import EditIcon from "../../assets/images/note.svg";
import StackIcon from "../../assets/images/stack.svg";
import RightArrowIcon from "../../assets/images/arrow-right.svg";

function AgendaNav(props) {
  return (
    <div className="agenda-nav" onClick={props.changeTab}>
      <img
        className="stack"
        src={props.add ? EditIcon : StackIcon}
        alt="block"
      />
      <p>
        {props.label} {!props.add && <span>{props.counts}</span>}
      </p>
      {!props.add && (
        <img className="right" src={RightArrowIcon} alt="right arrow" />
      )}
    </div>
  );
}

export default AgendaNav;
