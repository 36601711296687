import React from "react";
import "./Input.css";
import InputIcon from "./InputIcon";

function Input(props) {
  return (
    <div className="form-input">
      {props.src && <InputIcon icon={props.src} label={props.label} />}
      <div className="form-input-field">
        {props.label && <label>{props.label}</label>}
        {props.type === "date" ? (
          <div>
            <input
              style={props.readOnly ? { color: "#8E8E8E" } : {}}
              type="date"
              name={`${props.name}-start`}
              min={props.startDate}
              max={props.endDate}
              onChange={props.onStartChange}
              readOnly={props.readOnly}
              placeholder={props.placeholder || ""}
            />
         
          </div>
        ) : (
          <input
            style={props.readOnly ? { color: "#8E8E8E" } : {}}
            type={props.type || "text"}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            readOnly={props.readOnly}
            list={props.list}
            placeholder={props.placeholder || ""}
          />
        )}
        {props.list && <datalist id={props.list}>{props.dataList}</datalist>}
      </div>
      {props.src2 && (
        <InputIcon
          onClick={props.showPassword}
          icon={props.src2}
          label={props.label}
        />
      )}
    </div>
  );
}

export default Input;
