import styled from "styled-components";

export const TableScroller = styled.div`
  width: 100%;
  overflow-x: scroll;
`;
export const Table = styled.table`
  width: 100%;
  padding: 0;
  margin: 0;
  border-collapse:collapse;
`;

export const TableHeader = styled.thead`
  width: 100%;
  background: #f8f8f8;
`;

export const TableBody = styled.tbody`
  width: 100%;
`;

export const Tr = styled.tr`
  width: 100%;
  border-bottom: ${(props) => `${props.borderWidth || "1px"} solid #eff1f3`};
  background: transparent;
`;

export const Td = styled.td`
  font-family: GilroyMed;
  font-weight: 500;
  font-size: ${(props) => props.fontSize || "11px"};
  text-align: ${(props) => props.textAlign || "left"};
  color: ${(props) => props.color || "#a0a0a0"};
  padding: ${(props) => props.padding || "8px 10px"};
  background: transparent;
  white-space: nowrap;
  :first-child {
    border-radius:5px 0 0 5px;
  }
  :last-child {
    border-radius:0 5px 5px 0;
  }

  .th {
    display: flex;
    background: transparent;
    margin: 0;
  }
  .th > span {
    background: transparent;
    margin: auto 5px auto 0;
  }
  .th > img {
    background: transparent;
    margin: auto 0;
  }
  .up > img {
    transform: rotate(-180deg);
  }
`;
