import Selector from "./Selector";
import { useEffect } from "react";

function SelectorInput(props) {
  useEffect(() => {
    console.log("attendee", props);
  }, []);

  return (
    <div className="selector-input" style={props.style}>
      <div
        className={`selector-input-field ${
          !props.disableSelector ? "border-right" : ""
        }`}
      >
        {props.label && <label>{props.label}</label>}

        {props.selectOptions ? (
          <select
            type={props.type || "text"}
            name={props.name}
            value={props.value == "" ? props.selectedOption : props.value}
            onChange={props.onChange}
            readOnly={props.readOnly ? props.readOnly : false}
            style={{ color: props.readOnly ? "rgba(0,0,0,0.3)" : "" }}
          >
            {props.selectOptions.concat(["-"]).map((value) => (
              <option value={value}>
                {value}
              </option>
            ))}
          </select>
        ) : (
          <>
            <input
              type={props.type || "text"}
              name={props.name}
              value={props.value}
              onChange={props.onChange}
              readOnly={props.readOnly ? props.readOnly : false}
              style={{ color: props.readOnly ? "rgba(0,0,0,0.3)" : "" }}
            />
            {props.name == "contact" && (
              <div style={{ opacity: "0.7", fontSize: "12px" }}>
                Please enter with country code
              </div>
            )}
          </>
        )}
      </div>
      {!props.disableSelector && (
        <Selector
          selectedValues={props.selectedValues}
          selectValue={(option) => props.selectValue(option, props.name)}
          options={props.options}
        />
      )}
    </div>
  );
}

export default SelectorInput;
