import { useState, useEffect, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { Container } from "../components/Container.Style";
import Header from "../components/Header";
import Panel from "../components/Panel/Panel";
//conference panel components
import Conference from "../components/Conference/Conference";
import Attendees from "../components/Attendees/Attendees";
import Acquaintances from "../components/Acquaintances/Acquaintances";
//other panels
import Sponsors from "../components/Conference/Sponsors";
import Agendas from "../components/Agenda/Agendas";
import MyAgendas from "../components/Agenda/MyAgendas";
import Exhibits from "../components/Conference/Exhibit";
import Resources from "../components/Conference/Resources";

import conferenceServices from "../services/conference.services";
import attendeeServices from "../services/attendee.services";
import acquaintanceServices from "../services/acquaintance.services";
import exhibitServices from "../services/exhibit.services";

import { useAuth } from "../Auth";
import MainForm from "./Team";

import AgendaNav from "../components/Agenda/AgendaNav";
import { groupAgendas, getMyAgendas } from "../utils/tool";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

import TrashIcon from "../assets/images/trash-1.svg";

import conferenceService from "../services/conference.services";

import CheckBoxIcon from "../assets/images/checkbox.svg";
import CheckBoxActiveIcon from "../assets/images/checkbox-active.svg";

import parse from "html-react-parser";

function Dashboard() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useAuth();

  const panelRef = useRef(null);
  const mainPanelRef = useRef(null);
  const agendaPanelRef = useRef(null);

  const [mainTab, setMainTab] = useState({
    previousIndex: 0,
    currentIndex: 0,
    labels: [
      { label: "Dashboard", active: true },
      {
        label: "Sponsors",
        active: false,
        click: () => setSearchParams({ maintab: 1, tab: 1 }),
      },
      {
        label: "Agenda",
        active: false,
        click: () => setSearchParams({ maintab: 2, tab: 1 }),
      },
      {
        label: "Exhibits",
        active: false,
        click: () => setSearchParams({ maintab: 3, tab: 1 }),
      },
      {
        label: "Resources",
        active: false,
        click: () => setSearchParams({ maintab: 4, tab: 1 }),
      },
    ],
  });
  const [dashboardTab, setDashboardTab] = useState({
    previousIndex: 0,
    currentIndex: 0,
    labels: [
      {
        label: "Conference",
        active: true,
        click: () => setSearchParams({ maintab: 0, tab: 0 }),
      },
      {
        label: "Attendees",
        active: false,
        click: () => setSearchParams({ maintab: 0, tab: 1 }),
      },
      {
        label: "Acquaintance",
        active: false,
        click: () => setSearchParams({ maintab: 0, tab: 2 }),
      },
      {
        label: "My Team",
        active: false,
        click: () => setSearchParams({ maintab: 0, tab: 3 }),
      },
    ],
  });
  const [agendaTab, setAgendaTab] = useState({
    previousIndex: 0,
    currentIndex: 1,
    labels: [
      {
        label: "Dashboard",
        active: false,
        click: () => setSearchParams({ maintab: 0, tab: 0 }),
      },
      {
        label: "Agenda",
        active: true,
        click: () => setSearchParams({ maintab: 2, tab: 1 }),
      },
      {
        label: "My Agenda",
        active: false,
        click: () => setSearchParams({ maintab: 2, tab: 2 }),
      },
    ],
  });
  const [conference, setConference] = useState({});
  const [attendees, setAttendees] = useState([]);
  const [acquaintances, setAcquaintances] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [myAgendas, setMyAgendas] = useState([]);

  const [exhibits, setExhibits] = useState([]);

  const getQueries = () => {
    const searchQuery = new URLSearchParams(location.search);
    const query = {};
    ["maintab", "tab"].forEach((tabname) => {
      query[tabname] = Number.parseInt(searchQuery.get(tabname) || 0);
    });
    return query;
  };

  //change main tabs
  function changeMainTab(index) {
    mainTab.labels[mainTab.currentIndex].active = false;
    mainTab.labels[index].active = true;
    mainTab.previousIndex = mainTab.currentIndex;
    mainTab.currentIndex = index;
    setMainTab({ ...mainTab });
  }
  //change dashboard tabs
  function changeTab(index) {
    if (dashboardTab.currentIndex === index) return;
    dashboardTab.labels[dashboardTab.currentIndex].active = false;
    dashboardTab.labels[index].active = true;
    dashboardTab.previousIndex = dashboardTab.currentIndex;
    dashboardTab.currentIndex = index;
    setDashboardTab({ ...dashboardTab });
  }
  //change agenda tabs
  function changeAgendaTab(index, state) {
    const agendaTabState = state || agendaTab;
    if (agendaTabState.currentIndex === index) return;
    agendaTabState.labels[agendaTabState.currentIndex].active = false;
    agendaTabState.labels[index].active = true;
    agendaTabState.previousIndex = agendaTabState.currentIndex;
    agendaTabState.currentIndex = index;
    setAgendaTab({ ...agendaTabState });
  }

  //sort attendees
  function sortAttendees(col) {
    const asc = col.startsWith("-");
    const id = col.replace("-", "");

    if (id == "team") {
      setAttendees([
        ...attendees.sort((a, b) => {
          const teamA = parseInt(a[id] ? a[id].match(/\d+/)[0] : "-1");
          const teamB = parseInt(b[id] ? b[id].match(/\d+/)[0] : "-1");

          if (asc) {
            return teamA - teamB;
          } else {
            return teamB - teamA;
          }
        }),
      ]);
    } else {
      setAttendees([
        ...attendees.sort((a, b) => {
          if (!a[id] || !a[id]) return 0;
          const nameA = a[id]?.toUpperCase(); // ignore upper and lowercase
          const nameB = b[id]?.toUpperCase(); // ignore upper and lowercase
          if (nameA > nameB) {
            return asc ? -1 : 1;
          }
          if (nameA < nameB) {
            return asc ? 1 : -1;
          }
          return 0;
        }),
      ]);
    }
  }

  function updateExhibitNote(exhibit) {
    const exIndex = exhibits.findIndex(
      (ex) => `${ex._id}` === `${exhibit.exhibitId}`
    );
    if (exIndex < 0) return;
    exhibits[exIndex].note = exhibit;
    setExhibits([...exhibits]);
  }

  // Changing panel tabs
  useEffect(() => {
    if (!panelRef.current) return;
    const childWidth = panelRef.current.children[0].clientWidth;
    panelRef.current.scrollTo(dashboardTab.currentIndex * childWidth, 0);
    // console.log(dashboardTab.currentIndex);
  }, [dashboardTab.currentIndex]);

  useEffect(() => {
    if (!mainPanelRef.current) return;
    const childWidth = mainPanelRef.current.children[0].clientWidth;
    mainPanelRef.current.scrollTo(mainTab.currentIndex * childWidth, 0);
    //change subtab from
    const query = getQueries();
    switch (query.maintab) {
      case 0:
        console.log("1. Changing dashboard tab!");
        changeTab(query.tab);
        break;
      case 2:
        console.log("3. changing agenda tab!");
        changeAgendaTab(query.tab);
        break;
      default:
        console.log("4. None");
        break;
    }
  }, [mainTab.currentIndex]);

  useEffect(() => {
    if (!agendaPanelRef.current) return;
    const childWidth = agendaPanelRef.current.children[0].clientWidth;
    agendaPanelRef.current.scrollTo(
      (agendaTab.currentIndex - 1) * childWidth,
      0
    );
  }, [agendaTab.currentIndex]);

  useEffect(() => {
    const query = getQueries();
    if (mainTab.currentIndex === query.maintab) {
      switch (query.maintab) {
        case 0:
          changeTab(query.tab);
          break;
        case 2:
          changeAgendaTab(query.tab);
          break;
        default:
          console.log("4. None");
          break;
      }
    } else {
      changeMainTab(query.maintab || 0);
    }
  }, [location.search]);

  //load page data
  useEffect(() => {
    if (!auth.user) return;
    //LOAD CONFERENCE DATA
    conferenceServices
      .getConference(auth.user.conferenceId)
      .then((res) => {
        setConference(res.data.conference);
      })
      .catch((err) => {
        alert(err.message);
      });

    //LOAD ATTENDEES DATA
    attendeeServices
      .getAttendees({ conferenceId: auth.user.conferenceId, sort: "name" })
      .then((res) => {
        setAttendees(res.data.attendees);
      })
      .catch((err) => {
        alert(err.message);
      });

    //LOAD ACQUAINTANCES
    acquaintanceServices
      .getAcquaintances()
      .then((res) => {
        setAcquaintances(res.data.acquaintances);
      })
      .catch((err) => {
        alert(err.message);
      });

    //LOAD EXHIBITS
    exhibitServices
      .fetchExhibits(auth.user.conferenceId)
      .then((res) => {
        setExhibits(res.data.exhibits);
      })
      .catch((err) => {
        console.log(err.message);
      });

    //LOAD AGENDAS
    conferenceServices
      .fetchAgendas(auth.user.conferenceId)
      .then((res) => {
        console.log("res.data.agendas", res.data.agendas);
        setAgendas(res.data.agendas);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [auth.user]);

  const getMyCustomAgendas = (data, user) => {
    let events = [];
    data.map((value, index) => {
      let at = value.attendees;
      if (at && at.length > 0) {
        let ad = at.find((a) => a.attendee == user._id);
        if (ad && ad.checked) {
          events.push(value);
        }
      }
    });
    return events;
  };

  useEffect(() => {
    setMyAgendas(agendas);
  }, [agendas]);

  return (
    <Container>
      <Header
        child1={{
          classname: "left hide",
        }}
        child2={{
          classname: "center",
        }}
        child3={{
          classname: "right",
        }}
        profile={auth?.user?.profile}
      />

      <Panel
        style={{
          width: "100%",
          padding: 0,
          height: "calc(100% - 81px)",
        }}
        className="panel-modifier"
        tabs={[]}
        parentRef={mainPanelRef}
      >
        {/*DASHBOARD MAIN PANEL 1*/}
        <Panel
          tabs={dashboardTab.labels}
          parentRef={panelRef}
          changeTab={changeTab}
        >
          <Conference
            classname="page"
            conference={conference}
            participants={attendees.slice(0, 5)}
            totalParticipants={attendees.length}
            gotoAttendees={() => setSearchParams({ maintab: 0, tab: 1 })}
            changeMainTab={(index) =>
              setSearchParams({
                maintab: index,
                tab: 1,
              })
            }
            isExhibits={exhibits.length}
          />
          <Attendees
            classname="page"
            attendees={attendees}
            sort={sortAttendees}
          />
          <Acquaintances
            classname="page"
            setAcquaintances={setAcquaintances}
            acquaintances={acquaintances}
            setMessage={auth.setMessagePopup}
          />
          <MainForm
            classname="page"
            attendees={attendees}
            sort={sortAttendees}
          />
        </Panel>
        {/*SPONSORS MAIN PANEL 2*/}
        <Panel
          tabs={[
            {
              label: "Dashboard",
              active: false,
              click: () => setSearchParams({ maintab: 0, tab: 0 }),
            },
            { label: "Sponsors", active: true },
          ]}
          tabstyles={{ justifyContent: "space-around" }}
        >
          <Sponsors
            classname="page"
            sponsors={conference?.sponsorships || []}
          />
        </Panel>

        {/*AGENDA MAIN PANEL 3*/}
        <Panel
          parentRef={agendaPanelRef}
          tabs={agendaTab.labels}
          tabstyles={{ justifyContent: "space-around" }}
          changeTab={changeAgendaTab}
        >
          {/* <Agendas
            user={auth.user || {}}
            agendas={agendas}
            setAgendas={setAgendas}
            changeTab={() => setSearchParams({ maintab: 2, tab: 2 })}
            classname="page"
          /> */}
          <CustomAgendas
            agendas={agendas}
            changeTab={() => setSearchParams({ maintab: 2, tab: 2 })}
            classname="page"
            user={auth.user || {}}
            setAgendas={setAgendas}
            tab={1}
          />
          <CustomAgendas
            agendas={getMyCustomAgendas(myAgendas, auth.user || {})}
            changeTab={() => setSearchParams({ maintab: 2, tab: 2 })}
            classname="page"
            user={auth.user || {}}
            setAgendas={setMyAgendas}
            tab={2}
          />

          {/* <MyAgendas
            user={auth.user || {}}
            setMessage={auth.setMessagePopup}
            agendas={agendas}
            setAgendas={setAgendas}
            classname="page"
            conference={conference}
          /> */}
        </Panel>
        {/*EXHIBIT MAIN PANEL 3*/}
        <Panel
          tabs={[
            {
              label: "Dashboard",
              active: false,
              click: () => setSearchParams({ maintab: 0, tab: 0 }),
            },
            { label: "Exhibits", active: true },
          ]}
          tabstyles={{ justifyContent: "space-around" }}
        >
          <Exhibits
            updateExhibitNote={updateExhibitNote}
            classname="page"
            map={conference.map}
            exhibits={exhibits}
          />
        </Panel>
        <Panel
          tabs={[
            {
              label: "Dashboard",
              active: false,
              click: () => setSearchParams({ maintab: 0, tab: 0 }),
            },
            { label: "Resources", active: true },
          ]}
          tabstyles={{ justifyContent: "space-around" }}
        >
          <Resources classname="page" />
        </Panel>
      </Panel>
    </Container>
  );
}

const CustomAgendas = ({
  agendas,
  changeTab,
  classname,
  user,
  setAgendas,
  tab,
}) => {
  const months = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  const [showDescription, setShowDescription] = useState([]);

  const [progress, setProgress] = useState([]);

  const [singleAgendaProgress, setSingleAgendaProgress] = useState(true);

  const [agendaData, setAgendaData] = useState([]);

  const [currentTab, setCurrentTab] = useState(
    new URLSearchParams(window.location.search).get("tab")
  );

  const [mainTab, setMainTab] = useState(
    new URLSearchParams(window.location.search).get("maintab")
  );

  useEffect(() => {
    setCurrentTab(new URLSearchParams(window.location.search).get("tab"));
    setMainTab(new URLSearchParams(window.location.search).get("maintab"));
  }, [window.location.search]);

  const sortAgendasByDate = (data) => {
    const events = [...data];

    const eventsByDate = {};

    events.forEach((event) => {
      const { date } = event;

      if (!eventsByDate[date]) {
        eventsByDate[date] = {
          date,
          agendas: [],
        };
      }

      eventsByDate[date].agendas.push(event);
    });

    const groupedEvents = Object.values(eventsByDate);

    setAgendaData(groupedEvents);
  };

  useEffect(() => {
    sortAgendasByDate(agendas);
  }, [agendas]);

  useEffect(() => {
    if (agendaData.length > 0 && showDescription.length == 0) {
      let falseDataForDes = [];

      agendaData.map((value) => {
        let f1 = [];

        value.agendas.map((v) => {
          f1.push(false);
        });
        falseDataForDes.push(f1);
      });

      setShowDescription([...falseDataForDes]);
    }
  }, [agendaData]);

  useEffect(() => {
    if (showDescription.length > 0) {
      setSingleAgendaProgress(false);
    }
  }, [showDescription]);

  function updateAgendaTopic(topic, action, progress) {
    progress(true);
    const data = { [action]: true };
    if (topic.attendees && topic.attendees.length) {
      let att = topic.attendees.find(
        (attendee) => attendee.attendee == user._id
      );

      data[action] = att[action] ? false : true;
    }
    conferenceService
      .updateAgenda(user.conferenceId, topic._id, data)
      .then((res) => {
        let agenda = { ...topic };

        if (!agenda) return;

        if (!agenda.attendees) {
          agenda.attendees = [
            {
              attendee: user._id,
              ...data,
            },
          ];

          let si = agendas.findIndex((ag) => ag._id == agenda._id);

          agendas[si] = agenda;
          setAgendas([...agendas]);

          return;
        }

        if (!agenda.attendees.length) {
          agenda.attendees.push({
            attendee: res.data.userId,
            ...data,
          });
          let si = agendas.findIndex((ag) => ag._id == agenda._id);

          agendas[si] = agenda;
          setAgendas([...agendas]);
          return;
        }

        let atti = agenda.attendees.findIndex(
          (attendee) => attendee.attendee == user._id
        );

        agenda.attendees[atti] = { ...agenda.attendees[atti], ...data };

        let si = agendas.findIndex((ag) => ag._id == agenda._id);

        agendas[si] = agenda;
        setAgendas([...agendas]);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => progress(false));
  }

  return (
    <div
      className={`agendas ${classname} ${
        (mainTab == 2) & (currentTab == tab) ? "" : "hideScroll"
      }`}
    >
      {tab == 1 && (
        <AgendaNav
          changeTab={changeTab}
          label={"My Agenda"}
          counts={getMyAgendas(agendas || []).length}
        />
      )}

      <div className="label_agenda_pst">
        All times in PST (Pacific Standard Time)
      </div>
      <div style={{ width: "100vw", marginBottom: "400px" }}>
        {agendaData.map((ag, ind) => (
          <>
            <div style={{ marginTop: "20px" }}>
              {months[ag.date.split("-")[1]] + 1}/{ag.date.split("-")[0]}/2023
            </div>

            <div>
              {ag.agendas.map((value, index) => (
                <div>
                  <div className="agenda">
                    <div className="type">
                      <div
                        className={`topic ${
                          singleAgendaProgress && "topic-loader"
                        }`}
                        style={{ width: "90vw" }}
                      >
                        <div></div>
                        <div className="time">{value.time}</div>
                        <div className="content">
                          <p className="text-content">{value.topic}</p>
                          <p className="address">{value.location}</p>
                          <p className="address">
                            {value.type ? value.type : "-"}
                          </p>
                        </div>

                        <div className="action">
                          {showDescription.length > 0 && (
                            <button
                              className={`dropdown-button ${
                                showDescription && "expanded"
                              }`}
                              onClick={() => {
                                let fd = [...showDescription];

                                fd[ind][index] = !fd[ind][index];

                                setShowDescription(fd);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={
                                  showDescription.length > 0 &&
                                  showDescription[ind] &&
                                  showDescription[ind][index]
                                    ? faArrowUp
                                    : faArrowDown
                                }
                              />
                            </button>
                          )}
                          {value.personal ? (
                            <>
                              <img
                                onClick={() =>
                                  updateAgendaTopic(value, "remove", (p) => {
                                    // let fp = [...progress];

                                    // fp[ind][index] = p;
                                    setSingleAgendaProgress(p);
                                  })
                                }
                                src={TrashIcon}
                                alt="checkbox"
                                style={{
                                  paddingRight: "40px",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                onClick={() =>
                                  updateAgendaTopic(value, "checked", (p) => {
                                    // let fp = [...progress];

                                    // fp[ind][index] = p;
                                    setSingleAgendaProgress(p);
                                  })
                                }
                                src={
                                  value.attendees &&
                                  value.attendees.find(
                                    (attendee) => attendee.attendee == user._id
                                  ) &&
                                  value.attendees.find(
                                    (attendee) => attendee.attendee == user._id
                                  ).checked
                                    ? CheckBoxActiveIcon
                                    : CheckBoxIcon
                                }
                                alt="checkbox"
                                style={{
                                  paddingRight: "40px",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      {showDescription.length > 0 && showDescription[ind] && (
                        <>
                          {showDescription[ind][index] && (
                            <div style={{ width: "90vw" }}>
                              <p className="address">
                                {parse(value.description)}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
