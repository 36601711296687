import { ExhibitAPIs } from "../apis";
import { get, post } from "../utils/request";

async function fetchExhibits(conferenceId) {
  const response = await get({
    url: ExhibitAPIs.fetchExhibits(conferenceId),
  });
  if (response.status !== "success") throw response;
  return response;
}

async function updateNote(exhibitId, data) {
  const response = await post({
    url: ExhibitAPIs.addNoteOnExhibit(exhibitId),
    data,
  });
  if (response.status !== "success") throw response;
  return response;
}

const services = {
  fetchExhibits,
  updateNote,
};

export default services;
