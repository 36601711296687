import CoverBg from "../../assets/images/yellow-bg.svg";
import DefaultIcon from "../../assets/images/default-avatar.jpg";
import LinkedInIcon from "../../assets/images/profile/linkedIn-II.svg";
import MessageIcon from "../../assets/images/profile/message-II.svg";
import { AiOutlinePhone } from "react-icons/ai";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { TbWorldWww } from "react-icons/tb";
import NoteIcon from "../../assets/images/note.svg";

import Social from "./Social";

function CoverProfile(props) {
  return (
    <div className="cover-wrapper">
      <div className="cover-image">
        <img src={CoverBg} alt="cover bg" />
      </div>
      <div className="cover-profile">
        <img src={props.profile?.fileurl || DefaultIcon} alt={props.name} />
        <h4>{props.name}</h4>
        <p>{props.company}</p>
        <div className="socials">
          {props.note.noteCheck ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.note.openNote();
              }}
            >
              <Social profile={null} platform="note" icon={NoteIcon} />
            </div>
          ) : null}
          {props.linkedIn && (
            <Social
              profile={props.linkedIn}
              platform="linkedIn"
              icon={LinkedInIcon}
            />
          )}
          {/* {props.website && (
            <Social
              profile={props.website}
              platform="website"
              icon={
                <TbWorldWww
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    fontSize: "24px",
                  }}
                />
              }
            />
          )} */}
          {props.email && (
            <Social
              profile={`mailto:${props.email}`}
              platform="mail"
              icon={MessageIcon}
            />
          )}
          {props.phone && (
            <Social
              profile={`tel:${props.phone}`}
              platform="phone"
              icon={
                <AiOutlinePhone
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    fontSize: "24px",
                    transform: "rotate(90deg)",
                  }}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CoverProfile;
