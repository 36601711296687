// src/Privacy.js

import React from 'react';

const Privacy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy for NetworkingTag</h1>
      <p><strong>Effective Date:</strong> 1st July 2023</p>

      <h2>1. Introduction</h2>
      <p>
        NetworkingTag ("we," "our," or "us") is committed to protecting the privacy of our users ("you," "your"). 
        This Privacy Policy outlines how we collect, use, store, and protect your information when you use our mobile application ("App") and related services. 
        By using NetworkingTag, you agree to the collection and use of information in accordance with this policy.
      </p>

      <h2>2. Information We Collect</h2>
      <h3>a. Personal Information:</h3>
      <p>
        We collect personal information that you voluntarily provide when you register for an account, use the App, or interact with other users. 
        This may include your name, email address, phone number, company details, job title, and any other information you choose to share.
      </p>
      <h3>b. Automatically Collected Information:</h3>
      <p>
        We automatically collect certain information when you use the App, including your device’s IP address, device type, operating system, app usage data, and location information.
      </p>
      <h3>c. QR Code Information:</h3>
      <p>
        When you use the QR code feature to exchange contact details, we collect and store the information shared between users.
      </p>

      <h2>3. How We Use Your Information</h2>
      <ul>
        <li>To provide, operate, and maintain the App and its features.</li>
        <li>To enable you to create and manage your conference agenda.</li>
        <li>To facilitate networking by allowing users to connect via QR codes and store acquaintance details.</li>
        <li>To enhance the user experience by improving our services based on your feedback.</li>
        <li>To communicate with you about updates, new features, and other information related to the App.</li>
        <li>To monitor and analyze usage and trends to improve our services.</li>
      </ul>

      <h2>4. Data Storage and Security</h2>
      <h3>a. Data Storage:</h3>
      <p>
        Your personal information is stored securely on our servers. We take appropriate measures to protect your data from unauthorized access, alteration, disclosure, or destruction.
      </p>
      <h3>b. Data Retention:</h3>
      <p>
        We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
      </p>
      <h3>c. Data Security:</h3>
      <p>
        We implement industry-standard security measures to protect your data. However, please note that no method of transmission over the internet or method of electronic storage is 100% secure.
      </p>

      <h2>5. Sharing Your Information</h2>
      <p>We do not sell, trade, or rent your personal information to third parties. We may share your information with:</p>
      <ul>
        <li><strong>Service Providers:</strong> We may employ third-party companies and individuals to facilitate our services, provide services on our behalf, or assist us in analyzing how our App is used.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
      </ul>

      <h2>6. Your Rights and Choices</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access and update your personal information.</li>
        <li>Delete your account and personal information.</li>
        <li>Opt-out of receiving promotional communications.</li>
        <li>Restrict the processing of your data under certain circumstances.</li>
      </ul>
    <p>To exercise these rights, please contact us at admin@networkingtag.com.</p>
    <h2>7. Third-Party Links</h2>
    <p>Our App may contain links to other websites or services that are not operated by us. If you click on a third-party link, you will be directed to that third party’s site.</p>

    </div>
  );
};

export default Privacy;
