export const getFormattedDate = (date, separator, format) => {
  if(!date) return "";
  const newdate = new Date(date);
  const appendZero = (number) => (number < 10 ? `0${number}` : number);
  const value = {
    DD: appendZero(newdate.getDate()),
    MM: appendZero(newdate.getMonth() + 1),
    YY: appendZero(newdate.getFullYear()),
  };
  return format
    .split(separator)
    .map((field) => value[field])
    .join(separator);
};

export const getTime = (date) => {
  const newdate = new Date(date);
  // Get the hours, minutes, and AM/PM indicator
  var hours = newdate.getHours();
  var minutes = newdate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
 
  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Add leading zeros to minutes
  minutes = minutes < 10 ? "0" + minutes : minutes;

  // Combine into a string with the desired format
  return hours + ":" + minutes + " " + ampm;
};


export const get24Time = (time) => {
  const date = new Date(time);
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  
  return `${hours}:${minutes}:${seconds}`;
};
