import { useRef } from "react";
import "./PopupModal.css";

function PopupModal(props) {
  const modelRef = useRef(null);

  return (
    <div
      className="popup-modal"
      style={{ height: props.active ? "100%" : "0", display:"flex" }}
      id="main"
      onClick={(e) => {
        if (e.target.id !== "main") return;
        props.closeModal();
      }}
    >
      {!props.message ? (
        <div
          ref={modelRef}
          className="popup-modal-body"
          style={{
            height: `${props.active ? (props.height || 380) : 0}px`,
            padding: `${props.active ? "5%" : "0"}`,
          }}
        >
          <p className="popup-head">
            <img src={props.headIcon} alt={props.headLabel} />
            <span>{props.headLabel}</span>
          </p>
          {props.children}
        </div>
      ) : (
        props.children
      )}
    </div>
  );
}

export default PopupModal;
