import { useState } from "react";
import { useNavigate } from "react-router-dom";

import MailIcon from "../assets/images/inputs/mail.svg";
import LockIcon from "../assets/images/inputs/lock.svg";
import EyeIcon from "../assets/images/inputs/eye.svg";
import EyeIconClose from "../assets/images/eye-close.svg";
import Logo from "../assets/images/main-logo.png";

import Input from "../components/Input/Input";
import Button from "../components/Input/Button";

import { Container } from "../components/Container.Style";
import { FormContainer, FormBody, FormHead } from "../components/Form.Style";

import authServices from "../services/auth.services";
import { useAuth } from "../Auth";

function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [progress, setProgress] = useState(false);
  const [show, setShow] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = () => {
    if (progress) return;
    setProgress(true);
    authServices
      .authenticate({ email, password })
      .then((res) => {
        window.localStorage.setItem("token", res.token);
        const redirectUrl = new URLSearchParams(window.location.search).get(
          "redirect"
        );
        auth.setData({ loading: false, logged: true, user: res.data.user });
        localStorage.setItem("user", JSON.stringify(res.data.user));
        navigate(redirectUrl ? redirectUrl : "/Dashboard");
      })
      .catch((err) => {
        auth.setMessagePopup({ message: err.message, status: "failed" });
      })
      .finally(() => setProgress(false));
  };

  return (
    <Container>
      <FormContainer>
        <FormHead>
          <img src={Logo} alt="main-logo" />
          <p>Please Enter Your Login Credentials</p>
        </FormHead>
        <FormBody style={{ width: "100%" }}>
          <Input
            src={MailIcon}
            value={email}
            type="email"
            name="email"
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value || "")}
          />
          <Input
            src={LockIcon}
            src2={show ? EyeIcon : EyeIconClose}
            value={password}
            type={show ? "text" : "password"}
            name="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value || "")}
            showPassword={() => setShow(!show)}
          />
          <br />
          <Button
            onClick={login}
            btnType={`${progress ? "disabled" : ""} loginBtn`}
            name={progress ? "Please Wait! Logging you in" : "Login"}
          />

          <p className="link" onClick={() => navigate("/forget-password")}>
            Forget Password ?
          </p>
        </FormBody>
      </FormContainer>
    </Container>
  );
}

export default Login;
