import "../components/Attendees/Attendees.css";

import Search from "../components/Input/Search";
import MiniHead from "../components/MiniHead";
import AttendeesList from "../components/Attendees/AttendeesList";
import { useState, useEffect } from "react";

function MainForm(props) {
  const [search, setSearch] = useState("");
  const [team, setTeam] = useState([]);
  const [user, setUser] = useState();

  const findMemberByName = async (array, name) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].name == name) {
        setUser(array[i]);
        return array[i];
      }
    }
    return null;
  };

  const findMembersByTeam = async (array, name) => {
    let teamData = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].team == name) {
        teamData.push(array[i]);
      }
    }
    return teamData;
  };

  useEffect(() => {
    if (props.attendees.length > 0) {
      const fetchData = async () => {
        let userData = JSON.parse(localStorage.getItem("user"));
        const userMember = await findMemberByName(
          props.attendees,
          userData.name
        );
        if (userMember) {
          const members = await findMembersByTeam(
            props.attendees,
            userMember.team
          );
          setTeam(members);
        }
      };
      fetchData();
    }
    // console.log("aatt", props.attendees);
  }, [props.attendees]);

  const [currentTab, setCurrentTab] = useState(
    new URLSearchParams(window.location.search).get("tab")
  );

  useEffect(() => {
    setCurrentTab(new URLSearchParams(window.location.search).get("tab"));
  }, [window.location.search]);

  return (
    <div
      style={currentTab == 3 ? { overflow: "auto" } : {}}
      className={`attendees ${props.classname}`}
    >
      <Search search={search} onChange={(e) => setSearch(e.target.value)} />
      <div style={{ paddingLeft: "16px" }}>
        <MiniHead
          head={"Members"}
          data={
            user != undefined
              ? { name: user.name, room: user.team }
              : { name: "", room: "" }
          }
          count={team.length}
          chat={{ show: true }}
        />
      </div>
      <AttendeesList filter={search} sort={props.sort} attendees={team} />
    </div>
  );
}

export default MainForm;
