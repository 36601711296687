import Tag from "./Tag";
import CompanyIcon from "../../assets/images/profile/company-II.svg";
import UserIcon from "../../assets/images/profile/user-II.svg";
import EditIcon from "../../assets/images/profile/note-II.svg";

function Description(props) {
  return (
    <div className="profile-description">
      <div className="grid">
        {props.company && <Tag name={props.company} icon={CompanyIcon} />}
        {props.designation && <Tag name={props.designation} icon={UserIcon} />}
        {props.lastTag && (
          <Tag
            styles={{ width: "40px" }}
            name={props.lastTag.name}
            icon={props.lastTag.icon || EditIcon}
            onClick={props.lastTag.click}
          />
        )}
      </div>
      <p>{props.description}</p>
    </div>
  );
}

export default Description;
