import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./Acquaintances.css";

import NoteIcon from "../../assets/images/note.svg";

import Search from "../Input/Search";
import MiniHead from "../MiniHead";
import AcqInfo from "./AcquaintanceInfo";
import PopupModal from "../PopupModal/PopupModal";
import Button from "../Input/Button";
import Note from "../Input/Note";

import acquaintanceServices from "../../services/acquaintance.services";

import axios from "axios";
import { saveAs } from "file-saver";

function Acquaintances(props) {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const [search, setSearch] = useState("");
  const [acquaintance, setAcquaintance] = useState({ editor: false });

  function openNote(id) {
    const acq = props.acquaintances.find((acq) => `${acq._id}` === id);
    if (!acq) return;
    setAcquaintance({ ...acq, editor: true });
  }

  function syncNote(id, note) {
    const acqIndex = props.acquaintances.findIndex(
      (acq) => `${acq._id}` === id
    );
    if (acqIndex < 0) return;
    props.acquaintances[acqIndex].note = note;
    props.setAcquaintances([...props.acquaintances]);
  }

  function updateAcquaintance() {
    if (progress) return;
    setProgress(false);
    acquaintanceServices
      .updateAcquaintance(acquaintance._id, { note: acquaintance.note })
      .then(() => {
        //update local state
        syncNote(acquaintance._id, acquaintance.note);
        setAcquaintance({ editor: false });
        setProgress(false);
        props.setMessage({
          message: `${acquaintance.attendeeId?.name || ""} Note Saved!`,
          status: "success",
        });
      })
      .catch((err) => {
        props.setMessage({
          message: err.message,
          status: "failed",
        });
      });
  }

  const [currentTab, setCurrentTab] = useState(
    new URLSearchParams(window.location.search).get("tab")
  );

  useEffect(() => {
    setCurrentTab(new URLSearchParams(window.location.search).get("tab"));
  }, [window.location.search]);

  const ORIGIN = "https://api.networkingtag.com";
  // const ORIGIN = "http://127.0.0.1:5003";

  return (
    <>
      <div
        style={currentTab == 2 ? { overflow: "auto" } : {}}
        className={`acquaintances ${props.classname}`}
      >
        <Search
          search={search || ""}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div
          style={{
            paddingLeft: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MiniHead head={"Counts"} count={props.acquaintances.length} />
          <div
            style={{
              marginRight: "20px",
              backgroundColor: "#F7AD00",
              color: "white",
              width: "70px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            <a
              href={`${ORIGIN}/api/v1/attendees/exportV1?attendeeId=${
                JSON.parse(localStorage.getItem("user"))?._id
              }`}
              className="atag"
            >
              Export
            </a>
          </div>
        </div>
        {props.acquaintances
          .filter((acq) => {
            if (!search) return true;
            if (!acq?.attendeeId?.name) return false;
            return acq.attendeeId.name.match(new RegExp(search, "i"));
          })
          .map((acq, index) => {
            if (!acq.attendeeId) return;
            return (
              <AcqInfo
                key={index}
                profile={acq.attendeeId?.profile?.fileurl}
                name={acq.attendeeId?.name}
                company={acq.attendeeId?.company}
                click={() => navigate(`/attendees/${acq.attendeeId._id}`)}
                // click={() => navigate(`/acquaintances/${acq._id}`)}
                changeNote={() => openNote(`${acq._id}`)}
                note={acq.note ? true : false}
              />
            );
          })}
        <PopupModal
          closeModal={() => setAcquaintance({ editor: false })}
          headIcon={NoteIcon}
          headLabel={"Write a note"}
          active={acquaintance.editor}
        >
          <AcqInfo
            profile={acquaintance.attendeeId?.profile?.fileurl}
            name={acquaintance.attendeeId?.name}
            company={acquaintance.attendeeId?.company}
            click={() => navigate(`/acquaintances/${acquaintance._id}`)}
            hideNote={true}
          />
          <Note
            placeholder={"Start writing here"}
            limit={200}
            value={acquaintance.note || ""}
            onChange={(value) => {
              setAcquaintance({ ...acquaintance, note: value });
            }}
          />
          <Button
            onClick={updateAcquaintance}
            name={progress ? "Saving..." : "Save"}
            style={{
              padding: "12px 17px",
              margin: "auto",
            }}
            btnType="loginBtn"
          />
        </PopupModal>
      </div>
    </>
  );
}

export default Acquaintances;
