import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MailIcon from "../assets/images/inputs/mail.svg";
import LockIcon from "../assets/images/inputs/lock.svg";
import UserIcon from "../assets/images/inputs/user.svg";
import CompanyIcon from "../assets/images/inputs/company.svg";
import ContactIcon from "../assets/images/inputs/call.svg";
import Logo from "../assets/images/main-logo.png";
import EyeIcon from "../assets/images/inputs/eye.svg";
import EyeIconClose from "../assets/images/eye-close.svg";

import Input from "../components/Input/Input";
import Button from "../components/Input/Button";

import attendeeServices from "../services/attendee.services";
import { Container } from "../components/Container.Style";
import { FormContainer, FormBody, FormHead } from "../components/Form.Style";

import SelectorInput from "../components/Input/SelectorInput";

function Register() {
  const params = useParams();
  const navigate = useNavigate();
  const options = useRef([
    { label: "Only Me", value: "only me" },
    { label: "Acquaintance", value: "acquaintance" },
    { label: "Team", value: "team" },
    { label: "Everyone", value: "everyone" },
  ]);

  const [show, setShow] = useState(false);

  const [currentAttendee, setCurrentAttendee] = useState(null);

  const [show1, setShow1] = useState(false);

  const [progress, setProgress] = useState(false);
  const [registered, setRegistered] = useState({
    status: false,
    message: "",
  });
  const [formInfo, setFormInfo] = useState({
    name: "",
    email: "",
    company: "",
    contact: "",
    password: "",
    confirm_password: "",
  });
  const [otherInformations, setOtherInformations] = useState([
    {
      name: "company",
      label: "Company",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
      disableSelector: true,
      show: false,
    },
    {
      name: "state",
      label: "State",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
      disableSelector: true,
      show: true,
    },
    {
      name: "businessType",
      label: "Business Type",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
      selectOptions: [
        "Bank/Financial Institution",
        "BPO/Service Provider",
        "Consultant",
        "Consumer Product/Service/Utility/Healthcare",
        "Debt Buyer",
        "Digital Lender (Fintech)",
        "Government",
        "Law Firm",
        "Technology Provider",
        "Other",
      ],
      show: true,
    },
    {
      name: "jobTitle",
      label: "Job Title",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
      disableSelector: true,
      show: true,
    },
    // {
    //   name: "jobType",
    //   label: "Job Type",
    //   value: "",
    //   accessibleTo: [{ value: "only me", label: "Only Me" }],
    // },
    {
      name: "jobLevel",
      label: "Job Level",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
      selectOptions: [
        "Corporate Office/Executive",
        "VP/Division Head",
        "Manager/Department Lead",
        "Professional",
        "Coordinator/Assistant",
        "Other",
      ],
      show: true,
    },
    {
      name: "role",
      label: "Role",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
      selectOptions: [
        "Customer Relations",
        "Finance/Accounting",
        "Credit Risk & Underwriting",
        "General Executive Management",
        "Legal",
        "Marketing",
        "Operations",
        "Product Management",
        "Risk & Compliance",
        "Sales & Business Development",
        "Technology",
        "Other",
      ],
      show: true,
    },
    {
      name: "priorAttendance",
      label: "Your prior WCF attendance",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
      selectOptions: [
        "I've attended in person",
        "I've only attended virtually",
        "My first time",
      ],
      show: true,
    },
    {
      name: "interactionStyle",
      label: "Your interaction style",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
      selectOptions: [
        "Primarily Introvert",
        "Primarily Extrovert",
        "A true balance of both",
      ],
      show: true,
    },
    // {
    //   name: "teamExperience",
    //   label: "Team experience preference*",
    //   value: "",
    //   accessibleTo: [{ value: "only me", label: "Only Me" }],
    // },
    // {
    //   name: "considerYourself",
    //   label: "Do you consider yourself an*",
    //   value: "",
    //   accessibleTo: [{ value: "only me", label: "Only Me" }],
    // },
  ]);

  const [personalInformations, setPersonalInformations] = useState([
    {
      name: "contact",
      label: "Contact",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
    },
    {
      name: "email",
      label: "Email Address",
      value: "",
      accessibleTo: [{ value: "only me", label: "Only Me" }],
    },
  ]);

  const handleFormInput = (e) => {
    formInfo[e.target.name] = e.target.value;
    setFormInfo({ ...formInfo });
  };

  const handleSelectorInput = (e) => {
    const index = otherInformations.findIndex(
      (info) => info.name === e.target.name
    );
    if (index < 0) return;
    otherInformations[index].value = e.target.value;
    setOtherInformations([...otherInformations]);
  };

  const selectAccessibility = (option, name) => {
    const index = otherInformations.findIndex((info) => info.name === name);
    if (index < 0) return;

    const selectedLength = otherInformations[index].accessibleTo.length;

    const accessibleTo = otherInformations[index].accessibleTo.filter(
      (ac) => ac.value !== option.value
    );

    if (accessibleTo.length === selectedLength)
      otherInformations[index].accessibleTo.push(option);
    else {
      if (!accessibleTo.length) {
        accessibleTo.push({
          label: "Only Me",
          value: "only me",
        });
      }
      otherInformations[index].accessibleTo = accessibleTo;
    }

    setOtherInformations([...otherInformations]);
  };

  const handleSetAccessibility = async (option, name, options, setOptions) => {
    const index = options.findIndex((info) => info.name === name);
    if (index < 0) return;
    let accessibleTo = options[index].accessibleTo;
    const check = accessibleTo.findIndex((item) => item.value === option.value);
    if (check === -1) {
      if (option.value == "everyone" || option.value == "only me") {
        accessibleTo = [];
      } else {
        for (let i = accessibleTo.length - 1; i >= 0; i--) {
          if (["only me", "everyone"].includes(accessibleTo[i].value)) {
            accessibleTo.splice(i, 1);
          }
        }
      }
      accessibleTo.push(option);
    } else {
      accessibleTo.splice(check, 1);
      if (accessibleTo.length == 0) {
        accessibleTo.push({
          label: "Only Me",
          value: "only me",
        });
      }
    }
    let pf = [...options];
    pf[index].accessibleTo = accessibleTo;
    setOptions([...pf]);
  };

  const register = () => {
    if (progress) return;

    if (formInfo.confirm_password != formInfo.password) {
      alert("Confirm password does not match.");
      return 0;
    }

    setProgress(true);

    // let pi = [...personalInformations];

    // ["email", "contact"].map((value) => {
    //   pi.map((p) => {
    //     if (p.name == value) {
    //       p.value = formInfo[value];
    //     }
    //   });
    // });

    let data = [...otherInformations];

    // data.concat(pi);

    // console.log("data", data, otherInformations, pi, formInfo);

    attendeeServices
      .registerAttendee(params.id, {
        ...formInfo,
        state: data.state,
        otherInformations: data,
      })
      .then(() => {
        setRegistered({
          status: true,
          message: `Your account is successfully registered with this email ${formInfo.email}`,
        });
      })
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => setProgress(false));
  };

  //fetch user details
  useEffect(() => {
    if (!params.id) return;

    attendeeServices
      .registerationStatus(params.id)
      .then((response) => {
        const attendee = response.data.attendee;

        setCurrentAttendee(attendee);

        if (attendee.registered) {
          setRegistered({
            status: true,
            message: `Your already registered with this email ${attendee.email}`,
          });
        } else {
          try {
            formInfo.name = attendee.name;
            formInfo.email = attendee.email;
            formInfo.contact = attendee.contact;
            formInfo.company = attendee.company;
            setFormInfo({ ...formInfo });
          } catch (e) {}
        }
      })
      .catch((err) => {
        console.log("err", err);

        setRegistered({
          status: true,
          message: "Invalid Registeration Link!",
        });
      });
  }, [params.id]);

  // useEffect(() => {
  //   if (currentAttendee && currentAttendee.otherInformation) {
  //     let pi = [...personalInformations];

  //     if (currentAttendee.contact) {
  //       pi[0].value = currentAttendee.contact;
  //       setPersonalInformations(pi);
  //     }

  //     if (currentAttendee.company) {
  //       otherInformations[0].value = currentAttendee.company;
  //     }

  //     [
  //       "",
  //       "",
  //       "businessType",
  //       "",
  //       "jobLevel",
  //       "role",
  //       "priorAttendance",
  //       "interactionStyle",
  //     ].map((value, index) => {
  //       if (value != "") {
  //         let dv = currentAttendee.otherInformation.find((o) => o.key == value);
  //         if (dv) {
  //           otherInformations[index].value = dv.value;
  //         }
  //       }
  //     });

  //     let state = currentAttendee.otherInformation.find(
  //       (o) => o.key == "state"
  //     );

  //     if (state) {
  //       console.log("statestate", state);
  //       otherInformations[1].value = state.value;
  //     }

  //     let jobTitle = currentAttendee.otherInformation.find(
  //       (o) => o.key == "jobTitle"
  //     );

  //     if (jobTitle) {
  //       console.log("jobTitlejobTitle", jobTitle);

  //       otherInformations[3].value = jobTitle.value;
  //     }
  //   }
  // }, [currentAttendee]);

  const [confirmOtherInformationValues, setConfirmOtherInformationValues] =
    useState(false);

  const handleConfirmOtherInformationValues = async () => {
    let otherInfoTemp = JSON.stringify(otherInformations);
    let otherInfo = JSON.parse(otherInfoTemp);

    otherInformations.map((value, index) => {
      if (value.show) {
        let dv = currentAttendee.otherInformation.find(
          (o) => o.key == value.name
        );
        if (dv) {
          otherInfo[index].value = dv.value;
        }
      }
    });

    setOtherInformations(otherInfo);
  };

  useEffect(() => {
    if (
      currentAttendee &&
      currentAttendee.otherInformation &&
      !confirmOtherInformationValues
    ) {
      handleConfirmOtherInformationValues();
    }
  }, [currentAttendee]);

  const reConfirm = () => {
    let check = true;

    if (currentAttendee && currentAttendee.otherInformation) {
      otherInformations.every((value, index) => {
        if (value.show) {
          let dv = currentAttendee.otherInformation.find(
            (o) => o.key == value.name
          );

          if (value.value != dv.value) {
            handleConfirmOtherInformationValues();
            check = false;
            return false;
          }
        }

        return true;
      });

      setConfirmOtherInformationValues(check);
    }
  };

  useEffect(() => {
    if (!confirmOtherInformationValues) {
      reConfirm();
    }
  }, [otherInformations]);

  return (
    <Container>
      {!registered.status ? (
        <FormContainer>
          <FormHead>
            <img src={Logo} alt="main-logo" />
            <p>Please enter your valid information to continue</p>
          </FormHead>
          <FormBody style={{ width: "100%" }}>
            <Input
              src={UserIcon}
              value={formInfo.name || ""}
              type="text"
              name="name"
              placeholder="Name"
              readOnly={true}
            />
            {/* <Input
              src={MailIcon}
              value={formInfo.email || ""}
              type="email"
              name="email"
              placeholder="Email Address"
              readOnly={true}
            /> */}
            <SelectorInput
              key={"email"}
              value={formInfo.email || ""}
              type="text"
              label={"Email Address"}
              name={"email"}
              onChange={handleFormInput}
              selectedValues={personalInformations[1].accessibleTo}
              selectValue={(option, name) => {
                handleSetAccessibility(
                  option,
                  name,
                  personalInformations,
                  setPersonalInformations
                );
              }}
              options={options.current}
              readOnly={true}
            />
            <Input
              src={CompanyIcon}
              value={formInfo.company || ""}
              type="text"
              name="company"
              placeholder="Company Name"
              onChange={handleFormInput}
            />
            {/* <Input
              src={ContactIcon}
              value={formInfo.contact || ""}
              type="tel"
              name="contact"
              placeholder="Contact"
              onChange={handleFormInput}
            /> */}
            <SelectorInput
              key={"contact"}
              value={formInfo.contact || ""}
              type="text"
              label={"Contact"}
              name={"contact"}
              onChange={handleFormInput}
              selectedValues={personalInformations[0].accessibleTo}
              selectValue={(option, name) => {
                handleSetAccessibility(
                  option,
                  name,
                  personalInformations,
                  setPersonalInformations
                );
              }}
              options={options.current}
            />
            <Input
              src={LockIcon}
              src2={show ? EyeIcon : EyeIconClose}
              value={formInfo.password || ""}
              type={show ? "text" : "password"}
              name="password"
              placeholder="Password"
              onChange={handleFormInput}
              showPassword={() => setShow(!show)}
            />

            <Input
              src={LockIcon}
              src2={show1 ? EyeIcon : EyeIconClose}
              value={formInfo.confirm_password || ""}
              type={show1 ? "text" : "password"}
              name="confirm_password"
              placeholder="Confirm Password"
              onChange={handleFormInput}
              showPassword={() => setShow1(!show1)}
            />

            {confirmOtherInformationValues &&
              otherInformations.map((info, index) => {
                return (
                  <>
                    {info.show ? (
                      <SelectorInput
                        selectOptions={
                          info.selectOptions ? info.selectOptions : null
                        }
                        selectedOption={
                          currentAttendee &&
                          currentAttendee.otherInformation.find(
                            (o) => o.key == info.name
                          ).value
                        }
                        key={index}
                        value={info.value}
                        type="text"
                        label={info.label}
                        name={info.name}
                        onChange={handleSelectorInput}
                        selectedValues={info.accessibleTo}
                        // selectValue={selectAccessibility}
                        selectValue={(option, name) => {
                          handleSetAccessibility(
                            option,
                            name,
                            otherInformations,
                            setOtherInformations
                          );
                        }}
                        options={options.current}
                        disableSelector={
                          info.disableSelector ? info.disableSelector : false
                        }
                      />
                    ) : null}
                  </>
                );
              })}
            <br />
            <Button
              onClick={register}
              btnType={`${progress ? "disabled" : ""} loginBtn`}
              name="Submit"
            />
          </FormBody>
        </FormContainer>
      ) : (
        <FormContainer style={{ margin: "auto" }}>
          <FormHead>
            <img src={Logo} alt="main-logo" />
            <p>{registered.message}</p>
          </FormHead>
          <FormBody>
            <p className="link" onClick={() => navigate("/")}>
              Login
            </p>
          </FormBody>
        </FormContainer>
      )}
    </Container>
  );
}

export default Register;
