import { useEffect, useState } from "react";
import ComingSoon from "../../assets/images/work-in-progress.png";

function Resources(props) {
  return (
    <div className={props.classname}>
      <div
        style={{
          height: "calc(100% - 150px)",
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
        }}
      >
        {/* <img src={ComingSoon} width="200px" height="200px"></img> */}

        <ol>
          <li>
            <a
              className="workshop_materials_text"
              href=" https://www.womeninconsumerfinance.com/2023-workshop-materials"
            >
              Workshop Materials
            </a>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Resources;
