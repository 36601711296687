import ArrowRight from "../../assets/images/arrow-right.svg";
import DefaultIcon from "../../assets/images/default-avatar.jpg";
import NoteIcon from "../../assets/images/note.svg";

import Button from "../Input/Button";

function AcqInfo(props) {
  return (
    <div className="acq-list">
      {!props.hide && (
        <img
          className="acq-icon"
          src={props.profile || DefaultIcon}
          alt={props.name}
        />
      )}
      <div className="acq-details">
        <h5>{props.name}</h5>
        {props.booth && <p>{`Booth ${props.booth}`}</p>}
        {props.company && <p>{props.company}</p>}
      </div>
      {!props.hideNote && (
        <Button
          onClick={props.changeNote}
          name={props.note ? "Edit Note" : "Add Note"}
          icon={NoteIcon}
          btnType="acqNoteBtn no-effect"
        />
      )}
      {props.click && (
        <img
          style={{ marginLeft: props.changeNote ? "15px" : "auto" }}
          onClick={props.click}
          className="acq-nav"
          src={ArrowRight}
          alt={props.navigate}
        />
      )}
    </div>
  );
}

export default AcqInfo;
