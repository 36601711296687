import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Auth";

import { Container } from "../components/Container.Style";
import Header from "../components/Header";
import ProfileComponent from "../components/Profile/Profile";

import attendeeServices from "../services/attendee.services";

function Profile() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [profile, setProfile] = useState({});

  useEffect(() => {
    attendeeServices
      .attendeeProfile()
      .then((res) => setProfile(res.data.user))
      .catch((err) => alert(err.message));
  }, []);

  return (
    <Container display="block">
      <Header
        child1={{
          classname: "left",
        }}
        child2={{
          classname: "center",
          head: "About",
        }}
        child3={{
          classname: "right",
        }}
        profile={auth?.user?.profile}
      />
      <ProfileComponent
        lastTag={{
          name: "Edit.",
          click:() => navigate("/profile/edit")
        }}
        profile={profile}
      />
    </Container>
  );
}

export default Profile;
