import { useRef } from "react";
import { FormBody } from "../Form.Style";
import InputII from "../Input/Input-II";
import LinkedInIcon from "../../assets/images/inputs/linkedIn.svg";
import WebsiteIcon from "../../assets/images/inputs/website.svg";

function ProfileSocial(props) {
  const socialLinks = useRef([
    {
      icon: LinkedInIcon,
      name: "linkedIn",
      label: "LinkedIn",
      profile: "",
    },
    // {
    //   icon: WebsiteIcon,
    //   name: "website",
    //   label: "Website",
    //   profile: "",
    // },
  ]);

  const socialLink = (name) => {
    if (!props.profile?.socialLinks) return;
    const platform = props.profile.socialLinks.find(
      (link) => link.platform === name
    );
    if (!platform) return "";
    return platform.profile;
  };

  const updateSocialHandler = (e) => {
    if (!props.profile?.socialLinks) return;
    const index = props.profile?.socialLinks.findIndex(
      (link) => link.platform === e.target.name
    );
    if (index < 0) {
      props.profile.socialLinks.push({
        platform: e.target.name,
        profile: e.target.value,
      });
    } else {
      props.profile.socialLinks[index].profile = e.target.value;
    }
    props.setProfile({ ...props.profile });
  };

  return (
    <div className="profile-container">
      <h4>Socials</h4>
      <FormBody>
        {socialLinks.current.map((social, index) => {
          return (
            <InputII
              style={{ border: "1px solid #D9D9D9" }}
              key={index}
              label={social.label}
              name={social.name}
              src={social.icon}
              value={socialLink(social.name)}
              type="text"
              onChange={updateSocialHandler}
            />
          );
        })}
      </FormBody>
    </div>
  );
}

export default ProfileSocial;
