import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Moment from "react-moment";
import { Container } from "../components/Container.Style";
import Header from "../components/Header";
import { useAuth } from "../Auth";
import { io } from "socket.io-client";
import "../components/Chat.css";
import chatServices from "../services/chat.services";

const Chat = () => {
  const mainContainerRef = useRef();
  const location = useLocation();
  const msgBoxRef = useRef();
  const auth = useAuth();
  const chatBoxRef = useRef();
  const [data, setData] = useState({});
  const [message, setMessage] = useState("");
  const [allmsg, setAllmsg] = useState([]);
  const [socket, setSocket] = useState();
  const [scrollToBottom, setScrollToBottom] = useState(true);

  useEffect(() => {
    const socket = io("https://api.networkingtag.com");
    setSocket(socket);

    socket.on("connect", () => {
      socket.emit("joinRoom", location.state.room);
    });

    async function fetchChatHistory() {
      try {
        const history = await chatServices.getChatHistory(location.state.room);
        setAllmsg(history);
        scrollToBottomRef();
        scrollToBottomRefDocument();
      } catch (error) {
        console.log("Error fetching chat history:", error);
      }
    }
    fetchChatHistory();

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [location]);

  useEffect(() => {
    setData(location.state);
  }, [location]);

  useEffect(() => {
    if (socket) {
      socket.on("getLatestMessage", (newMessage) => {
        setAllmsg([...allmsg, newMessage]);
        setMessage("");
      });
    }
  }, [socket, allmsg]);

  useEffect(() => {
    scrollToBottomRef();
  }, [allmsg]);

  const scrollToBottomRef = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const scrollToBottomRefDocument = () => {
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollTop =
        mainContainerRef.current.scrollHeight;
    }
  };

  const submitHandle = () => {
    if (message) {
      const newMessage = {
        time: new Date(),
        message,
        name: data.name,
        room: data.room,
      };
      chatServices.saveChatMessage(newMessage);
      socket.emit("newMessage", { newMessage, room: data.room });
      setMessage("");
    }
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      submitHandle();
    }
  };

  return (
    <Container display="block" ref={mainContainerRef}>
      <Header
        child1={{
          classname: "left",
        }}
        child2={{
          classname: "center",
          head: "Team Chat",
        }}
        child3={{
          classname: "right",
        }}
        profile={auth?.user?.profile}
      />
      <div className="container py-4 m-5">
        <div className="text-center px-3 mb-4">
          <a href="/Dashboard" className="back-btn">
            ← Back
          </a>
        </div>

        {/* <div className="chat-box" style={{ height: "450px" }} ref={chatBoxRef}>
          {allmsg.map((msg) => (
            <div
              key={msg.time}
              className={`chat-message ${
                data.name === msg.name
                  ? "chat-message-right"
                  : "chat-message-left"
              }`}
            >
              <div>
                <strong>{msg.name}</strong>
                <small className="text-muted">
                  <Moment fromNow>{msg.time}</Moment>
                </small>
              </div>
              <h4>{msg.message}</h4>
            </div>
          ))}
          <div ref={msgBoxRef}></div>
        </div> */}
        <div className="chat-box" ref={chatBoxRef}>
          {allmsg.map((msg) => (
            <div
              key={msg.time}
              className={`chat-message ${
                data.name === msg.name
                  ? "chat-message-right"
                  : "chat-message-left"
              }`}
            >
              <div
                className={
                  data.name === msg.name ? "textBoxLeft" : "textBoxRight"
                }
              >
                <strong
                className={
                  data.name === msg.name ? "textBoxLeft" : "textBoxRight"
                }
                >
                  {msg.name}
                </strong>
                <small className="text-muted textBoxRight">
                  <Moment
                     className={
                      data.name === msg.name ? "textBoxLeft" : "textBoxRight"
                    }
                    fromNow
                  >
                    {msg.time}
                  </Moment>
                </small>
              </div>
              <h4
                className={
                  data.name === msg.name ? "textBoxLeft" : "textBoxRight"
                }
              >
                {msg.message}
              </h4>
            </div>
          ))}
          <div ref={msgBoxRef}></div>
        </div>

        <div style={{ paddingBottom: "30px" }} className="form-group d-flex">
          <input
            type="text"
            name="message"
            onKeyDown={handleEnter}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            placeholder="Type Your Message"
            className="form-control"
          />
          <button onClick={submitHandle} className="btn btn-warning mx-2">
            Send
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Chat;
