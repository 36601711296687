import CloseIcon from "../../assets/images/close.svg";

function MessageModal(props) {
  return (
    <div
      className={`popup-message-modal popup-message-${props.status || "none"}`}
    >
      <img src={CloseIcon} alt="message-close" onClick={props.close} />
      <p>{props.message}</p>
    </div>
  );
}

export default MessageModal;
