import styled from "styled-components";

export const FormContainer = styled.div`
  width: 90%;
  padding: 10% 2.5%;
  max-width: 350px;
  margin: 30px auto 0 auto;
`;

export const FormHead = styled.div`
  width: 100%;
  margin-bottom: 55px;
  img {
    display: block;
    width: 70%;
    margin: auto;
  }
  p {
    color: #000000;
    text-align: center;
    font-size: 14px;
    margin: 20px 0;
  }

  .failed {
    color: orangered;
  }
  .success {
    color: darkgreen;
  }
`;

export const FormBody = styled.div`
  width: 100%;

  .link {
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    color: #ffb300;
    cursor: pointer;
  }
`;
