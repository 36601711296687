import ImageIcon from "../../assets/images/inputs/image.svg";

function ProfilePic(props) {
  return (
    <div className="profile-wrapper">
      <div className="profile">
        <div className="border">
          <img src={props.picture} alt="profile" />
        </div>
        <div className="upload">
          <input type="file" onChange={props.updatePicture} accept="image/*" />
          <span className="img">
            <img src={ImageIcon} alt="upload" />
          </span>
        </div>
      </div>
      <p>Modify your profile here</p>
    </div>
  );
}

export default ProfilePic;
