import { ChatAPIs } from "../apis";
import { get, post } from "../utils/request";

async function getChatHistory(room) {
  try {
    //console.log(room);
    const response = await get({
      url: ChatAPIs.getHistory(room),
    });

    // console.log(response);
    return response;
  } catch (error) {
    console.error("Error fetching chat history:", error);
    throw error;
  }
}

async function saveChatMessage(newMessage) {
  try {
    const response = await post({
      url: ChatAPIs.save,
      data: newMessage,
      headers: {
        "content-type": "application/json",
      },
    });
    if (response.status !== "success") throw response;
  } catch (e) {
    console.log(e);
  }
}

const services = {
  saveChatMessage,
  getChatHistory,
};

export default services;
