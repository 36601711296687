import { useState } from "react";
import { useNavigate } from "react-router-dom";

import MailIcon from "../assets/images/inputs/mail.svg";
import Logo from "../assets/images/main-logo.png";

import Input from "../components/Input/Input";
import Button from "../components/Input/Button";

import { AuthAPIs } from "../apis";
import { Container } from "../components/Container.Style";
import { FormContainer, FormBody, FormHead } from "../components/Form.Style";

function Forget() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [progress, setProgress] = useState(false);

  const forgetPassword = () => {
    if (progress) return;
    setProgress(true);
    fetch(AuthAPIs.forgetPassword, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status !== "success") throw res;
        alert(res.message);
      })
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => setProgress(false));
  };

  return (
    <Container>
      <FormContainer>
        <FormHead>
          <img src={Logo} alt="main-logo" />
          <p>Please Enter your Email Address</p>
        </FormHead>
        <FormBody style={{ width: "100%" }}>
          <Input
            src={MailIcon}
            value={email}
            type="email"
            name="email"
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value || "")}
          />
          <br />
          <Button
            onClick={forgetPassword}
            btnType={`${progress ? "disabled" : ""} loginBtn`}
            name="Submit"
          />

          <p className="link" onClick={() => navigate("/")}>
            Login
          </p>
        </FormBody>
      </FormContainer>
    </Container>
  );
}

export default Forget;
