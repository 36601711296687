import { AuthAPIs } from "../apis";
import { get, post } from "../utils/request";

async function authenticate(credentials) {
  const response = await post({
    url: AuthAPIs.login,
    data: credentials,
    headers:{
      "content-type": "application/json",
    }
  });
  if (response.status !== "authorized") throw response;
  return response;
}

async function validate() {
  if (!window.localStorage.getItem("token"))
    throw { status: "fail", message: "Login Required!" };

  const response = await get({
    url: AuthAPIs.validateToken,
  });
  if (response.status !== "success") throw response;
  return response;
}

const services = {
  authenticate,
  validate,
};

export default services;
