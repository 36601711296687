import { AnalyticAPIs } from "../apis";
import { post } from "../utils/request";

async function trackTime(data) {
  const response = await post({
    url: AnalyticAPIs.trackTime,
    data,
    headers: {
      "content-type": "application/json",
    },
  });
  if (response.status !== "success") throw response;
  return response;
}

const services = {
  trackTime,
};

export default services;
