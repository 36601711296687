import EyeIcon from "../../assets/images/inputs/eye-II.svg";
import ArrowDownIcon from "../../assets/images/inputs/arrow-down.svg";
import CheckboxIcon from "../../assets/images/checkbox.svg";
import CheckboxActiveIcon from "../../assets/images/checkbox-active.svg";

import { useRef, useState } from "react";

function Selector(props) {
  const [toggle, setToggle] = useState(false);
  const optionRef = useRef(null);
  const showValue = (values) => {
    // console.log(values);
    if (!values.length || values.find((v) => v.label === "Only Me"))
      return "Only Me";
    if (values.find((v) => v.label === "Everyone")) return "Everyone";
    if (values.length === 1 && values.find((v) => v.label === "Acquaintance"))
      return `${"Acquaintance".slice(0, 5)}...`;
    if (values.length === 1 && values.find((v) => v.label === "Team"))
      return "Team";
    return "Acq+Team";
  };
  const isSelected = (value, selectedValues = []) => {
    return selectedValues.find((v) => v.value === value) ? true : false;
  };

  const getHeight = () => {
    var height = 0;
    if (!optionRef.current) return height;
    [...optionRef.current.children].forEach(
      (child) => (height += child.clientHeight)
    );
    return height;
  };

  return (
    <div className="selector">
      <div onClick={() => setToggle(!toggle)} className="s-body">
        <img src={EyeIcon} alt="visibility" />
        <span>{showValue(props.selectedValues)}</span>
        <img src={ArrowDownIcon} alt="drop-down" />
      </div>
      <ul
        ref={optionRef}
        className="options"
        style={{ height: `${toggle ? getHeight() : 0}px` }}
      >
        {props.options.map((option, index) => (
          <li
            key={index}
            value={option.value}
            onClick={() => props.selectValue(option)}
          >
            <label>{option.label}</label>
            <img
              alt={option.label}
              src={
                isSelected(option.value, props.selectedValues)
                  ? CheckboxActiveIcon
                  : CheckboxIcon
              }
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Selector;
